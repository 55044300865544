<section class="search-by-filter-page">
	<div class="container">
		<div class="filters-holder">
			<mat-button-toggle-group class="dflt-btn-group" value="All">
			  <mat-button-toggle value="All">All</mat-button-toggle>
			  <mat-button-toggle value="Classes">Classes</mat-button-toggle>
			  <mat-button-toggle value="Camps">Camps</mat-button-toggle>
			  <mat-button-toggle value="Events">Events</mat-button-toggle>
			</mat-button-toggle-group>

			<mat-button-toggle-group class="dflt-btn-group" value="All">
			  <mat-button-toggle value="All">All</mat-button-toggle>
			  <mat-button-toggle value="Online">Online</mat-button-toggle>
			  <mat-button-toggle value="On-Demand">On-Demand</mat-button-toggle>
			  <mat-button-toggle value="Local">Local</mat-button-toggle>
			</mat-button-toggle-group>

			<!-- Filter by Location -->
			<div class="filter-dropdown-holder">
				<button mat-flat-button class="btn btn-tag btn-active" (click)="toggleLocation()">
					<i class="fal fa-map-marker-alt"></i>
					Broomfield, CO, 80021
					<i class="far fa-times close-i"></i>
				</button>
				<div class="filter-dropdown location-search-box" *ngIf="showLocation" (clickOutside)="showLocation = false">
					<div class="box-header">
						<i class="far fa-arrow-left back-btn visible-xs" (click)="toggleLocation()"></i>
						Location
						<i class="far fa-times close-btn hidden-xs" (click)="toggleLocation()"></i>
					</div>
					<div class="box-body">
						<div class="location-search-input">
							<a href="javascript:;" class="btn-map">
								<i class="fal fa-map-marker-alt"></i>
							</a>					
							<input type="text" class="form-control" [(ngModel)]="searchLocationFilter" [(value)]="searchLocationFilter">
							<a href="javascript:;" class="clear-btn" *ngIf="searchLocationFilter">
								<i class="fal fa-times" (click)="searchLocationFilter=''"></i>
							</a>
						</div>
						<ul>
							<li>
								<a href="javascript:;" class="txt-green">
									<i class="fal fa-location-arrow"></i>
									Current Location
								</a>
							</li>
							<li>
								<a href="javascript:;">
									<i class="fal fa-desktop"></i>
									Online
								</a>
							</li>
							<li>
								<a href="javascript:;">
									<i class="fas fa-check-circle txt-green"></i>
									Boulder, CO, 80024
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- Filter by Age -->
			<div class="filter-dropdown-holder">
				<button mat-flat-button class="btn btn-tag btn-active" (click)="toggleAge()">
					Age 2 -12
					<i class="far fa-times close-i"></i>
				</button>
				<div class="filter-dropdown filter-by-age" *ngIf="showAge" (clickOutside)="showAge = false">
					<div class="box-header">
						<i class="far fa-arrow-left back-btn visible-xs" (click)="toggleAge()"></i>
						Age Range
						<i class="far fa-times close-btn hidden-xs" (click)="toggleAge()"></i>
					</div>
					<div class="box-body">
						<div class="range-slider-holder">
							<form [formGroup]="rangeSliderAgeForm">
								<a href="javascript:;" class="clear" (click)="resetRangeSliderAgeForm()">Clear</a>
								<ngx-slider formControlName="rangeSliderAgeControl" [options]="optionsRangeSliderAge"></ngx-slider>
							</form>
						</div>
					</div>
				</div>
			</div>

			<!-- Filter by Language -->
			<div class="filter-dropdown-holder">
				<button mat-flat-button class="btn btn-tag">
					English
					<i class="far fa-chevron-down dropdown-i"></i>
				</button>
			</div>

			<!-- Filter by Day and Time -->
			<div class="filter-dropdown-holder">
				<button mat-flat-button class="btn btn-tag" (click)="toggleDateTime()">
					Day and Time
					<i class="far fa-chevron-down dropdown-i"></i>
				</button>
				<div class="filter-dropdown filter-by-date-n-time" *ngIf="showDateTime"  (clickOutside)="showDateTime = false">
					<div class="box-header">
						<i class="far fa-arrow-left back-btn visible-xs" (click)="toggleDateTime()"></i>
						Day and Time
						<i class="far fa-times close-btn hidden-xs" (click)="toggleDateTime()"></i>
					</div>
					<div class="box-body">
						<div class="day-holder">
							<h4>Day</h4>
							<ul>
								<li>
									<a href="javascript:;" mat-button>
										M
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										T
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										W
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										T
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										F
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										S
									</a>
								</li>
								<li>
									<a href="javascript:;" mat-button>
										S
									</a>
								</li>
							</ul>
						</div>
						<div class="range-slider-holder">
							<h4>Time</h4>
							<form [formGroup]="rangeSliderTodayOrDateForm">
								<a href="javascript:;" class="clear" (click)="resetRangeSliderTodayOrDateForm()">Clear</a>
								<ngx-slider formControlName="rangeSliderTodayOrDateControl" [options]="optionsTodayOrDate"></ngx-slider>
							</form>
						</div>
					</div>
				</div>
			</div>

			<!-- Filter by Today or Date -->
			<div class="filter-dropdown-holder">
				<button mat-flat-button class="btn btn-tag" (click)="toggleTodayOrDate()"> 
					Today or Date
					<i class="far fa-chevron-down dropdown-i"></i>
				</button>
				<div class="filter-dropdown filter-by-today-or-date" *ngIf="showTodayOrDate" (clickOutside)="showTodayOrDate = false">
					<div class="box-header">
						<i class="far fa-arrow-left back-btn visible-xs" (click)="toggleTodayOrDate()"></i>
						Today or Date Range
						<i class="far fa-times close-btn hidden-xs" (click)="toggleTodayOrDate()"></i>
					</div>
					<div class="box-body">
						<div class="checkbox-holder">
							<h4>Today</h4>
							<div class="input-box">
								<mat-checkbox color="primary" class="mat-checkbox-bdr-1">
									25 June 2020
								</mat-checkbox>				      	
						    </div>
						</div>
						<div class="or-divider">
							<span>OR</span>
						</div>
						<div class="row input-holder">
							<div class="col-12 input-title">
								<h4>Date Range</h4>
								<a href="javascript:;" class="clear">Clear</a>
							</div>
							<div class="col-6 input-box">
								<div class="mat-cst-input-fill-box">
									<mat-form-field class="mat-block no-underline" [floatLabel]="'never'">
									  <mat-label>From</mat-label>
									  <input matInput [matDatepicker]="picker">
									  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
									  <mat-datepicker-toggle matSuffix [for]="picker">
									    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
									  </mat-datepicker-toggle>
									  <mat-datepicker #picker (clickOutside)="showTodayOrDate = true"></mat-datepicker>
									</mat-form-field>
								</div>
							</div>

							<div class="col-6 input-box">
								<div class="mat-cst-input-fill-box">
									<mat-form-field class="mat-block no-underline" [floatLabel]="'never'">
									  <mat-label>To</mat-label>
									  <input matInput [matDatepicker]="picker2">
									  <mat-datepicker-toggle matPrefix [for]="picker2"></mat-datepicker-toggle>
									  <mat-datepicker-toggle matSuffix [for]="picker2">
									    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
									  </mat-datepicker-toggle>
									  <mat-datepicker #picker2 (clickOutside)="showTodayOrDate = true"></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<a href="javascript:;" class="more-filters-link  hidden-xs">
				More Filters
			</a>

		</div>
		<!-- Live Online Classes & Camps -->
		<div class="row slide-holder default-slider-holder">
			<div class="col-12 title-holder">
				<h1>Live Online Classes & Camps</h1>
				<p>Taught by qualified teachers over live video for as low as $5.</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>
		<!-- Classes near Boulder, CO -->
		<div class="row slide-holder default-slider-holder">
			<div class="col-12 title-holder">
				<h1>
					Classes near Boulder, CO 
					<a href="javascript:;"><i class="fal fa-map-marker-alt"></i></a>
				</h1>
				<p>Taught by qualified teachers over live video for as low as $5.</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>
		<!-- Live Online Classes & Camps -->
		<div class="row slide-holder default-slider-holder">
			<div class="col-12 title-holder">
				<h1>Live Online Classes & Camps</h1>
				<p>Taught by qualified teachers over live video for as low as $5.</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>
	</div>
</section>