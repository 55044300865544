import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  // Search dropdown
  opened: boolean;
  
  value: any;  
  public showCategories:boolean = false;
  toggleCategories() {
    this.showCategories = !this.showCategories;
  }
  // Slick Sliders==========
  slideConfigHomepageCategory = {
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button type="button" class="slick-prev category-slider-prev"><i class="far fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next category-slider-next"><i class="far fa-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }; 

  // Mobile Announcement=========
  public showAnnouncement:boolean = true;
  toggleAnnouncement() {
    this.showAnnouncement = !this.showAnnouncement;
  }

  ngOnInit(): void {
  	this.loadScript('../../assets/js/main.js');  
  }
  public loadScript(url) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
