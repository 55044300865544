import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.scss']
})
export class ManageLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Remove footer part=============
  	const body = document.getElementsByTagName('body')[0];
    body.classList.add('hidden-footer');
  }
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('hidden-footer');
  }

}
