<div class="container sitemap-holder">
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/home">
		Home Page
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/wildster-search">
		Search Page
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/sign-in">
		Sign In Page
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/sign-up">
		Sign Up Page
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/forget-password">
		Forget Password Page
	</button>
	<br />
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/onboarding">
		Onboarding
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/class">
		Class & Camp
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/class/recorded">
		Class Recorded
	</button>
	<br />
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/listings">
		Listings
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/listings/session-roster">
		Listings- Session Roster
	</button>
	<button mat-raised-button class="btn btn-cst-primary" routerLink="/listings/manage-locations">
		Listings- Manage Locations
	</button>
</div>