<section class="auth-default-template">
	<div class="row-flex">
		<!-- Left side content -->
		<div class="flex-col lhs-box hidden-xs">
			<div class="logo-holder">
				<a routerLink="/home" class="logo">
					<img src="assets/images/wildster-white-logo.png" alt="">
				</a>
			</div>
		</div>
		<!-- auth Holder -->
		<div class="flex-col auth-holder">
			<div class="auth-nav-top hidden-xs">
				Already have account? 
				<a routerLink="/sign-in">
					Sign in here
				</a>
			</div>
			<div class="flex-box">
				<div class="auth-box">
					<div class="logo-holder visible-xs">
						<a routerLink="/home">
							Wildster
						</a>
					</div>
					<h2 class="title">Forget Password</h2>
					<p class="subtitle">Enter your Email And we will sent you recover email </p>
					<div class="input-box-holder forget-pass-box">
						<div class="input-box">
					      <label>Email</label>
					      <input type="email" class="form-control" placeholder="">
					      <!-- <input type="email" class="form-control error-input" placeholder=""> -->
					      <!-- <p class="error-msg">Sorry Email is not registered with us</p> -->
					    </div>
						<div class="btn-holder">
					      	<button type="submit" mat-flat-button class="btn btn-block btn-submit">
						      	Forget Password
						    </button>
						    <p class="text-center visible-xs">
						    	Already have account? 
								<a routerLink="/sign-in">
									Sign in here
								</a>
						    </p>
					    </div>
					</div>
				</div>
				<div class="banner-holder visible-xs">
					<img src="assets/images/auth-bg-mobile.png" alt="">
				</div>
			</div>
		</div>
	</div>
</section>