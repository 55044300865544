<div class="class-n-camp-template">
	<!-- Recorded Videos -->
	<div class="recorded-video-holder">
		<div class="container-md">
			<div class="flex-box">
				<!-- Video Player -->
				<div class="recorded-video">
					<div mat-card-image plyr [plyrOptions]="options" class="dflt-vdo-player" 
      					[plyrPoster]="poster" [plyrSources]="videoSources"></div>
				</div>
				<!-- Video List for Desktop version -->
				<div class="video-list-holder hidden-xs">
					<div class="list-header">
						<div class="title">
							<h4>14 videos</h4>
							Total 3h 14m
						</div>
						<div class="btn-holder">
							<button type="button" mat-flat-button class="btn btn-enroll">
								Enroll Now
							</button>
						</div>
					</div>
					<div class="list-body">
						<ul>
							<li>
								<a href="javascript:;" matRipple>
									<!-- <div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div> -->
									<div class="vdo-progress">
										<circle-progress
										  [percent]="40"
										  [radius]="15"
										  [maxPercent]="100"
										  [outerStrokeWidth]="2.5"
										  [innerStrokeWidth]="0"
										  [showImage]="true"
										  [showBackground]="false"
										></circle-progress>
									</div>
									<div class="content">Introduction</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div>
									<!-- <div class="vdo-progress">
										<circle-progress
										  [percent]="35"
										  [radius]="15"
										></circle-progress>
									</div> -->
									<div class="content">Creating the Illustrator Document</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div>
									<!-- <div class="vdo-progress">
										<circle-progress
										  [percent]="35"
										  [radius]="15"
										></circle-progress>
									</div> -->
									<div class="content">Designing Your Character</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div>
									<!-- <div class="vdo-progress">
										<circle-progress
										  [percent]="35"
										  [radius]="15"
										></circle-progress>
									</div> -->
									<div class="content">Introduction</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div>
									<!-- <div class="vdo-progress">
										<circle-progress
										  [percent]="35"
										  [radius]="15"
										></circle-progress>
									</div> -->
									<div class="content">Creating the Illustrator Document</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="locked-icon">
										<i class="far fa-lock-alt"></i>
									</div>
									<!-- <div class="vdo-progress">
										<circle-progress
										  [percent]="35"
										  [radius]="15"
										></circle-progress>
									</div> -->
									<div class="content">Designing Your Character</div>
									<div class="time">1m 19s</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>	

	<!-- Main Contents -->
	<div class="content-holder">
		<div class="container">
			<!-- video titles and summary -->
			<div class="row cont-header">
				<div class="col-md-8 col-lg title-holder">
					<!-- status for mobile only -->
					<p class="status visible-xs">Online</p>
					<!-- status desktop -->
					<p class="status hidden-xs">CLASS • BOULDER, CO</p>
					<h1 class="title">
						Novel Study: Fever 1793 -<br />
						Twice Weekly Version
					</h1>
					<p class="author">
						<span>by Shredder Ski</span> 
						<a href="javascript:;" class="more-location hidden-xs">2 more locations <i class="fas fa-angle-down"></i></a>
					</p>
					<p class="save-n-share hidden-xs">						
						<a href="javascript:;">
							<i class="far fa-bookmark"></i>
							Save
						</a>
						<a href="javascript:;">
							<i class="far fa-share-alt"></i>
							Share
						</a>
					</p>
					<!-- reviews for mobile -->
					<div class="review-box visible-xs">
						<div class="review-circle">
							3.5
						</div>
						<p>3.5 out of 5</p>
					</div>
				</div>
				<!-- badge and opinions for desktop version -->
				<div class="col-lg ml-xl-auto hidden-xs">
					<div class="badge-holder">
						<div class="flx-box age-n-duratioin">
							<div class="cnt has-bdr-btm">
								<span>Age</span><br />
								2 – 5 years
							</div>
							<div class="cnt">
								<span>Duration</span><br />
								45 minutes
							</div>
						</div>
						<div class="flx-box opinions">
							<circle-progress
							  [percent]="78"
							  [radius]="60"
							  [maxPercent]="100"
							  [outerStrokeWidth]="7"
							  [innerStrokeWidth]="7"
							  [space]="-7"
							  [showBackground]="false"
							  [showSubtitle]="false"
							  [showUnits]="false"
							  [clockwise]="false"
							  [title]="4.1"
							  [outerStrokeColor]="'#fff'"
							  [titleColor]="'#fff'"
							  [titleFontWeight]="600"
							  [titleFontSize]="40"
							></circle-progress>
							<h5>300 opinions</h5>
						</div>
						<div class="flx-box badge-box">
							<img src="assets/images/badge.png" alt="">
						</div>		
					</div>			
				</div>
				<!-- Age and duration for mobile -->
				<div class="col-12 visible-xs">
					<div class="row age-n-duratioin-xs">
						<div class="col-6">							
							<div class="box">
								<span>Age</span><br />
								2 – 5 years
							</div>
						</div>
						<div class="col-6">							
							<div class="box">
								<span>Duration</span><br />
								45 minutes
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Video List for mobile version -->
			<div class="row video-list-holder visible-xs">
				<div class="list-header">
					<div class="title">
						<h4>14 videos</h4>
						Total 3h 14m
					</div>
					<div class="btn-holder">
						<button type="button" mat-flat-button class="btn btn-enroll">
							Enroll Now
						</button>
					</div>
				</div>
				<div class="list-body">
					<ul>
						<li>
							<a href="javascript:;" matRipple>
								<!-- <div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div> -->
								<div class="vdo-progress">
									<circle-progress
									  [percent]="40"
									  [radius]="15"
									  [maxPercent]="100"
									  [outerStrokeWidth]="2.5"
									  [innerStrokeWidth]="0"
									  [showImage]="true"
									  [showBackground]="false"
									></circle-progress>
								</div>
								<div class="content">Introduction</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
						<li>
							<a href="javascript:;" matRipple>
								<div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div>
								<!-- <div class="vdo-progress">
									<circle-progress
									  [percent]="35"
									  [radius]="15"
									></circle-progress>
								</div> -->
								<div class="content">Creating the Illustrator Document</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
						<li>
							<a href="javascript:;" matRipple>
								<div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div>
								<!-- <div class="vdo-progress">
									<circle-progress
									  [percent]="35"
									  [radius]="15"
									></circle-progress>
								</div> -->
								<div class="content">Designing Your Character</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
						<li>
							<a href="javascript:;" matRipple>
								<div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div>
								<!-- <div class="vdo-progress">
									<circle-progress
									  [percent]="35"
									  [radius]="15"
									></circle-progress>
								</div> -->
								<div class="content">Introduction</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
						<li>
							<a href="javascript:;" matRipple>
								<div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div>
								<!-- <div class="vdo-progress">
									<circle-progress
									  [percent]="35"
									  [radius]="15"
									></circle-progress>
								</div> -->
								<div class="content">Creating the Illustrator Document</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
						<li>
							<a href="javascript:;" matRipple>
								<div class="locked-icon">
									<i class="far fa-lock-alt"></i>
								</div>
								<!-- <div class="vdo-progress">
									<circle-progress
									  [percent]="35"
									  [radius]="15"
									></circle-progress>
								</div> -->
								<div class="content">Designing Your Character</div>
								<div class="time">1m 19s</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- Main Content Box -->
			<div class="row main-content-box">
				<div class="content-left mr-auto">
					<!-- Short description -->
					<div class="short-description">
						<h1>What the kids will do</h1>
						<p>
							This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
						</p>
						<p>
							First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests. These baaaad boys have appalling table manners, so you might like to join them with your own snacks.
						</p>
					</div>
					<!-- full description -->
					<div class="full-description">
						<h1>Things to know</h1>
						<mat-accordion class="dflt-description-expansion-panel">
						  <mat-expansion-panel #exp1>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Itinerary
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp1.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp1.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp2>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Learning Goals
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp2.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp2.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel [expanded]="true" #exp3>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Homework
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp3.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp3.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp4>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Supplies Needed
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp4.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp4.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp5>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        External Resources
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp5.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp5.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>						  
						  <mat-expansion-panel #exp6>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Parental Guidance
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp6.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp6.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						</mat-accordion>
					</div>
					<!-- banner bottom -->
					<div class="badge-n-banner-btm hidden-xs">
						<div class="badge-box">
							<img src="assets/images/badge-2.png" alt="">
						</div>
					</div>
				</div>
				<!-- Sidebar -->
				<div class="content-right">
					<div class="sidebar-item">
		                <div class="sticky hidden-xs">
		                	<!-- instructor panel -->
		                	<div class="sidebar-panel-box">
		                		<mat-expansion-panel class="sidebar-panel" [expanded]="true">
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        <div class="img-holder">
			                				<img src="assets/images/placeholder/instructor.png" alt="">
			                			</div>
			                			<div class="title-holder">
			                				<h1>Kimberly Simpson</h1>
			                				<p>About the instructor</p>
			                			</div>
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <div class="panel-body">
			                			<p>
			                				This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
		                				</p>
	                					<p>
											First we will meet online and chat farmyard will help you to relax with breathing.
			                			</p>
			                			<p class="view-profile">
			                				<a href="javascript:;">
			                					View instructor profile
			                				</a>
			                			</p>
			                		</div>
								  </mat-expansion-panel>
		                	</div>
		                </div>
		                <!-- instructor-panel for mobile -->
		                <div class="sidebar-panel-box visible-xs">
	                		<div class="panel-header">
						        <div class="img-holder">
	                				<img src="assets/images/placeholder/instructor.png" alt="">
	                			</div>
	                			<div class="title-holder">
	                				<h1>Roshna Meeran</h1>
	                				<p>Phd, B. Ed</p>
	                			</div>
	                		</div>
						    <div class="panel-body">
	                			<p>
	                				This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
	                			</p>
	                			<p>
									First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn.
	                			</p>
	                		</div>
	                	</div>
		            </div>					
				</div>
			</div>

		</div>
	</div>
</div>