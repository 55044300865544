<ngx-slick-carousel #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageDflt">
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-1.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-1.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<div class="item-left-badge visible-xs">2 spots left</div>		
					<div class="byage">Ages 5 – 12</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-2.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-2.png" class="visible-xs" alt="" />
			  	<label class="item-left-badge hidden-xs">2 spots left</label>
			</div>
			<div class="content">
				<p class="status">BOULDER</p>						
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<div class="item-left-badge visible-xs">2 spots left</div>		
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-3.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-1.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<!-- <div class="item-left-badge visible-xs">2 spots left</div>		 -->
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-4.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-2.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>							
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>		
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<!-- <div class="item-left-badge visible-xs">2 spots left</div>		 -->
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-1.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-1.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<div class="item-left-badge visible-xs">2 spots left</div>		
					<div class="byage">Ages 5 – 12</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-2.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-2.png" class="visible-xs" alt="" />
			  	<label class="item-left-badge hidden-xs">2 spots left</label>
			</div>
			<div class="content">
				<p class="status">BOULDER</p>						
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<div class="item-left-badge visible-xs">2 spots left</div>		
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-3.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-1.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>	
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<!-- <div class="item-left-badge visible-xs">2 spots left</div>		 -->
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
	<div ngxSlickItem>
	  <div class="box">
	  	<a href="javascript:;">
		  	<div class="thumbnail-holder">			  		
			  	<img src="assets/images/placeholder/online-class-4.png" class="hidden-xs" alt="" />
			  	<img src="assets/images/placeholder/online-class-mobile-2.png" class="visible-xs" alt="" />
			  	<!-- <label class="item-left-badge hidden-xs">2 spots left</label> -->
			</div>
			<div class="content">
				<p class="status">ONLINE</p>							
				<!-- progress circle -->							
				<div class="progress-circle">
		  			5.0
		  		</div>		
				<h4>The Five Paragraph Essay Made Easy</h4>
				<p class="auth-mbl visible-xs">By Gabrialla Horvath</p>
				<div class="cont-ftr">
					<div class="auth hidden-xs">By Gabrialla Horvath</div>					
					<!-- <div class="item-left-badge visible-xs">2 spots left</div>		 -->
					<div class="byage">Ages 4 – 7</div>
				</div>
			</div>
		</a>
	  </div>
	</div>
</ngx-slick-carousel>