<section class="onboarding-template">
	<div class="header-box sticky-top">
		<!-- Mobile step back buttons -->
		<button mat-button class="back-btn visible-xs" *ngIf="showStep == 2" (click)="stepToggle(1); progressValue(20);">
			<i class="far fa-long-arrow-left"></i>
		</button>
		<button mat-button class="back-btn visible-xs" *ngIf="showStep == 3" (click)="stepToggle(2); progressValue(40);">
			<i class="far fa-long-arrow-left"></i>
		</button>
		<button mat-button class="back-btn visible-xs" *ngIf="showStep == 4" (click)="stepToggle(3); progressValue(60);">
			<i class="far fa-long-arrow-left"></i>
		</button>
		<button mat-button class="back-btn visible-xs" *ngIf="showStep == 5" (click)="stepToggle(4); progressValue(80);">
			<i class="far fa-long-arrow-left"></i>
		</button>
		<!-- //End Mobile step back buttons -->

		Apply to Teach	

		<!-- Mobile step next buttons -->	
		<button mat-flat-button class="btn-next visible-xs" *ngIf="showStep == 1" (click)="stepToggle(2); progressValue(40);">Next</button>
		<button mat-flat-button class="btn-next visible-xs" *ngIf="showStep == 2" (click)="stepToggle(3); progressValue(60);">Next</button>
		<button mat-flat-button class="btn-next visible-xs" *ngIf="showStep == 3" (click)="stepToggle(4); progressValue(80);">Next</button>
		<button mat-flat-button class="btn-next visible-xs" *ngIf="showStep == 4" (click)="stepToggle(5); progressValue(90);">Next</button>
		<button mat-flat-button class="btn-next visible-xs" [disabled]="!disabledSubmit" *ngIf="showStep == 5" (click)="stepToggle(6);">Submit</button>
		<!-- //End Mobile step next buttons -->
	</div>	
	<div class="container-box" *ngIf="showStep !== 6">
		<div class="progress-bar-holder">
			<mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar>
		</div>
	</div>

	<!-- Online or In-Person -->
	<div class="container-box" *ngIf="showStep == 1">
		<div class="title-holder">
			<h1 class="title">
				Are you an online instructor or do you teach in-person?
			</h1>
		</div>
		<div class="radio-box-holder row">
			<div class="col-md-6 radio-box">
				<label matRipple>
				    <input type="radio" name="learningOption" value="Online Learning" checked="checked"/>
				    <div>
				    	<h4>Online Learning</h4>
				    	<p>
				    		Host live video chats and online discussions
				    	</p>
				    </div>
				</label>
			</div>
			<div class="col-md-6 radio-box">
				<label matRipple>
				    <input type="radio" name="learningOption" value="In-Person Learning" />
				    <div>
				    	<h4>In-Person Learning</h4>
				    	<p>
				    		List local classes and camps with the option to host online discussions
				    	</p>
				    </div>
				</label>
			</div>
		</div>
		<div class="btn-holder hidden-xs">
			<!-- <button mat-button class="back-btn">Back</button> -->
			<button mat-flat-button class="btn btn-next" (click)="stepToggle(2); progressValue(40);">Next</button>
		</div>
	</div>

	<!-- Business and Targeting -->
	<div class="container-box" *ngIf="showStep == 2">
		<div class="title-holder">
			<h1 class="title">
				Let’s create your online instructor profile.
			</h1>
		</div>
		<div class="input-box-holder row">
			<div class="input-box col-12 has-search-btn business-name hidden-xs">
			    <label>Business Name (optional)</label>
			    <input type="text" class="form-control" [ngClass]="{'opened-dropdown': opened}" placeholder="Type your business name" (click)="opened = !opened">
			    <a href="javascript:;" class="btn-search" matRipple>
			    	<i class="fas fa-search"></i>
			    </a>
			    <div class="search-dropdown" *ngIf="opened" (clickOutside)="opened = false">
					<div class="scrollable-box">
						<ul>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content match-item">
										<h4>Mike’s ABCs</h4>
										<p>Create a business with this name</p>	
									</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content">
										<h4>Mikealo Kids Business</h4>
									</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content">
										<h4>Mikealo Kids Business</h4>
									</div>
								</a>
							</li>								
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content">
										<h4>Mikealo Kids Business</h4>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- mobile -->
			<div class="input-box col-12 has-search-btn business-name visible-xs">
			    <label>Business Name (optional)</label>
			    <div data-toggle="modal" data-target="#modal-business-name">
				    <input type="text" class="form-control p-events-none-xs">
				    <a href="javascript:;" class="btn-search">
				    	<i class="fas fa-search"></i>
				    </a>
				</div>
			</div>		

			<div class="input-box col-12 target-demographics-holder hidden-xs">
				<label>Target demographics</label>
				<mat-form-field class="mat-block" appearance="outline" [ngClass]="{'mat-cst-focused': openedTargetdemographics}">
				  <mat-chip-list #chipList aria-label="Fruit selection">
				    <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
				             [removable]="removable" (removed)="remove(fruit)">
				      {{fruit.name}}
				      <i matChipRemove *ngIf="removable" class="far fa-times"></i>
				    </mat-chip>
				    <input [matChipInputFor]="chipList"
				           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				           [matChipInputAddOnBlur]="addOnBlur"
				           (matChipInputTokenEnd)="add($event)" (click)="openedTargetdemographics = !openedTargetdemographics">
				  </mat-chip-list>
				</mat-form-field>

				<div class="multi-slct-demographics-dropdown" *ngIf="openedTargetdemographics" (clickOutside)="openedTargetdemographics = false">
					<div class="content-holder">
						<div class="content">
							<mat-accordion class="multi-select-expansion-panel">
							  <mat-expansion-panel>
							    <mat-expansion-panel-header>
							      <mat-panel-title>
							        Art & Design
							      </mat-panel-title>
							    </mat-expansion-panel-header>
							    <ul>
							    	<li>
							    		<a href="javascript:;" matRipple>Fine and Applied Arts</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>History</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>Literature in English</a>
							    	</li>
							    </ul>
							  </mat-expansion-panel>
							  <mat-expansion-panel [expanded]="true">
							    <mat-expansion-panel-header>
							      <mat-panel-title>
							        Sports
							      </mat-panel-title>
							    </mat-expansion-panel-header>
							    <ul>
							    	<li>
							    		<a href="javascript:;" class="selected" matRipple>Basketball</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>Soccer</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>Table Tennis</a>
							    	</li>
							    </ul>
							  </mat-expansion-panel>
							  <mat-expansion-panel>
							    <mat-expansion-panel-header>
							      <mat-panel-title>
							        Performing Arts
							      </mat-panel-title>
							    </mat-expansion-panel-header>
							    <ul>
							    	<li>
							    		<a href="javascript:;" class="selected" matRipple>Dance</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>Music</a>
							    	</li>
							    	<li>
							    		<a href="javascript:;" matRipple>Circus Arts</a>
							    	</li>
							    </ul>
							  </mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>
				</div>
			</div>
			<!-- mobile -->
			<div class="input-box col-12 target-demographics-holder visible-xs">
				<label>Target demographics</label>
				<mat-form-field class="mat-block" appearance="outline" data-toggle="modal" data-target="#modal-target-demographics">
				  <mat-chip-list #chipList aria-label="Fruit selection">
				    <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
				             [removable]="removable" (removed)="remove(fruit)">
				      {{fruit.name}}
				      <i matChipRemove *ngIf="removable" class="far fa-times"></i>
				    </mat-chip>
				    <input [matChipInputFor]="chipList"
				           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				           [matChipInputAddOnBlur]="addOnBlur"
				           (matChipInputTokenEnd)="add($event)" class="d-none">
				  </mat-chip-list>
				</mat-form-field>
			</div>

		</div>
		<div class="btn-holder hidden-xs">
			<button mat-button class="back-btn" (click)="stepToggle(1); progressValue(20);">Back</button>
			<button mat-flat-button class="btn btn-next" (click)="stepToggle(3); progressValue(60);">Next</button>
		</div>
	</div>

	<!-- Business address and phone -->
	<div class="container-box" *ngIf="showStep == 3">
		<div class="title-holder">
			<h1 class="title">
				Let’s create your online instructor profile.
			</h1>
		</div>
		<div class="input-box-holder row">
			<div class="input-box col-12 has-search-btn business-address hidden-xs">
			    <label>Business address</label>
			    <input type="text" class="form-control" [ngClass]="{'opened-dropdown': opened}" placeholder="Search address" (click)="opened = !opened">
			    <a href="javascript:;" class="btn-search" matRipple>
			    	<i class="fas fa-search"></i>
			    </a>
			    <div class="search-dropdown" *ngIf="opened" (clickOutside)="opened = false">
					<div class="scrollable-box">
						<ul>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content match-item">
										<h4>7000 Broadyway Street</h4>
										<p>Denver, Colorado 80038</p>	
									</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content">
										<h4>7000 Boradway Lane</h4>
										<p>Queens, New York 90283</p>
									</div>
								</a>
							</li>
							<li>
								<a href="javascript:;" matRipple>
									<div class="flex-content">
										<h4>7000 Broadwrange Drive</h4>
										<p>Chicago, Illinois 60515</p>
									</div>
								</a>
							</li>	
						</ul>
					</div>
				</div>
			</div>
			<!-- mobile -->
			<div class="input-box col-12 has-search-btn business-address visible-xs">
			    <label>Business address</label>
			    <div data-toggle="modal" data-target="#modal-business-address">
				    <input type="text" class="form-control p-events-none-xs">
				    <a href="javascript:;" class="btn-search">
				    	<i class="fas fa-search"></i>
				    </a>
				</div>
			</div>	

			<div class="input-box col-12">
				<label>Business phone</label>
				<input type="text" class="form-control">
			</div>
		</div>
		<div class="btn-holder hidden-xs">
			<button mat-button class="back-btn" (click)="stepToggle(2); progressValue(40);">Back</button>
			<button mat-flat-button class="btn btn-next" (click)="stepToggle(4); progressValue(80);">Next</button>
		</div>
	</div>

	<!-- Online Instructor Experience -->
	<div class="container-box" *ngIf="showStep == 4">
		<div class="title-holder">
			<h1 class="title">
				Tell us about your teaching experience.
			</h1>
		</div>
		<div class="input-box-holder row">
			<div class="textarea-box col-12">
				<label>What is your experience in working with kids?</label>
				<textarea class="form-control" rows="3"></textarea>
				<p class="shortdsc" [ngClass]="{'show': isButtonVisible == 1}">
					Tip: You don’t need previous teaching experience to teach on Wildster. Be specific with years, subjects, age of learners, and any context regarding your teaching.
					<a href="javascript:;" class="more-btn visible-xs" (click)="ButtonVisible(1);">more</a>
				</p>
			</div>
			<div class="textarea-box col-12">
				<label>What is your experience in working with kids?</label>
				<textarea class="form-control" rows="3"></textarea>
				<p class="shortdsc" [ngClass]="{'show': isButtonVisible == 2}">
					Tip: You don’t need previous teaching experience to teach on Wildster. Be specific with years, subjects, age of learners, and any context regarding your teaching.
					<a href="javascript:;" class="more-btn visible-xs" (click)="ButtonVisible(2);">more</a>
				</p>
			</div>
			<div class="textarea-box col-12">
				<label>What is your experience in working with kids?</label>
				<textarea class="form-control" rows="3"></textarea>
				<p class="shortdsc" [ngClass]="{'show': isButtonVisible == 3}">
					Tip: You don’t need previous teaching experience to teach on Wildster. Be specific with years, subjects, age of learners, and any context regarding your teaching.
					<a href="javascript:;" class="more-btn visible-xs" (click)="ButtonVisible(3);">more</a>
				</p>
			</div>
		</div>
		<div class="btn-holder hidden-xs">
			<button mat-button class="back-btn" (click)="stepToggle(3); progressValue(60);">Back</button>
			<button mat-flat-button class="btn btn-next"  (click)="stepToggle(5); progressValue(90);">Next</button>
		</div>
	</div>

	<!-- Online Instructor Video -->
	<div class="container-box" *ngIf="showStep == 5">
		<div class="title-holder">
			<h1 class="title">
				Teach us something!
			</h1>
			<h4 class="subtitle">Tips for creating a video</h4>
		</div>
		
		<div class="video-recording-holder">
			<div class="video-holder" [ngClass]="{'height-auto': showVideo}">
				<a href="javascript:;" matRipple class="dlt-btn">
					<i class="far fa-trash"></i>
				</a>
				<div class="progress-bar-holder">
					<mat-progress-bar mode="determinate" [value]="30"></mat-progress-bar>
				</div>
				<div class="uploaded-video" *ngIf="showVideo">
					<div mat-card-image plyr [plyrOptions]="options" class="dflt-vdo-player uploaded-vdo" 
      					 [plyrSources]="videoSources"></div>
				</div>
			</div>
			<div class="vd-btn-holder">
				<button mat-flat-button class="btn btn-vdo hidden-xs">Start Recording</button>
				<button mat-flat-button class="btn btn-vdo visible-xs">Record</button>
				<!-- <button mat-flat-button class="btn btn-vdo">Re-record</button> -->
				<button mat-flat-button class="btn btn-vdo hidden-xs">Delete</button>
				<button mat-flat-button class="btn btn-vdo visible-xs">Upload</button>
			</div>
			<div class="checkbox-holder">
				<mat-checkbox color="primary" class="mat-checkbox-xl" [(ngModel)]="disabledSubmit">
					I agree to the <a href="javascript:;">Terms of Service</a>, <a href="javascript:;">Class Content Policy</a> and the <a href="javascript:;">Community Standards</a>.
				</mat-checkbox>				      	
		    </div>
		</div>

		<div class="btn-holder hidden-xs">
			<button mat-button class="back-btn" (click)="stepToggle(4); progressValue(80);">Back</button>
			<button mat-flat-button class="btn btn-submit" [disabled]="!disabledSubmit" (click)="stepToggle(6)">Submit Application</button>
		</div>
	</div>

	<!-- Online Instructor Success Page -->
	<div class="container-box" *ngIf="showStep == 6">
		<h1 class="success-msg">All done!</h1>
	</div>

</section>

<!-- ================All Mobile input popup/Modals================= -->
<!-- Mobile Business Name Input modal -->
<div class="modal modal-mobile-business-name fade" id="modal-business-name" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">	  
    	<div class="search-box-holder">
    		<a href="javascript:;" class="back-btn" data-dismiss="modal" aria-label="Close">    		
	      		<i class="far fa-long-arrow-left"></i>
		    </a>				
			<input type="text" class="form-control" [(ngModel)]="businessName">
			<a href="javascript:;" class="clear-btn" (click)="businessName=''">
				<i class="fal fa-times"></i>
			</a>
		</div>
		<div class="search-dropdown">
			<ul>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content match-item">
							<h4>Mike’s ABCs</h4>
							<p>Create a business with this name</p>	
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content">
							<h4>Mikealo Kids Business</h4>
							<p>1729 Majestic Dr STE 1, Lafayette., CO...</p>
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content">
							<h4>Mikealo Kids Business</h4>
							<p>1729 Majestic Dr STE 1, Lafayette., CO...</p>
						</div>
					</a>
				</li>								
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content">
							<h4>Mikealo Kids Business</h4>
							<p>1729 Majestic Dr STE 1, Lafayette., CO...</p>
						</div>
					</a>
				</li>
			</ul>
		</div>

    </div>
  </div>
</div>

<!-- Mobile Business Address Input modal -->
<div class="modal modal-mobile-business-address fade" id="modal-business-address" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">	  
    	<div class="search-box-holder">
    		<a href="javascript:;" class="back-btn" data-dismiss="modal" aria-label="Close">    		
	      		<i class="far fa-long-arrow-left"></i>
		    </a>				
			<input type="text" class="form-control" [(ngModel)]="businessAddress">
			<a href="javascript:;" class="clear-btn" (click)="businessAddress=''">
				<i class="fal fa-times"></i>
			</a>
		</div>
		<div class="search-dropdown">
			<ul>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content match-item">
							<h4>7000 Broadyway Street</h4>
							<p>Denver, Colorado 80038</p>	
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content">
							<h4>7000 Boradway Lane</h4>
							<p>Queens, New York 90283</p>
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						<div class="flex-content">
							<h4>7000 Broadwrange Drive</h4>
							<p>Chicago, Illinois 60515</p>
						</div>
					</a>
				</li>	
			</ul>
		</div>

    </div>
  </div>
</div>

<!-- Mobile Target demographics Input modal -->
<div class="modal modal-target-demographics fade" id="modal-target-demographics" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">	
    	<div class="modal-cst-header">  
	    	<a href="javascript:;" class="close-btn" data-dismiss="modal" aria-label="Close">    		
	      		<i class="fal fa-times"></i>
		    </a>
		    Select your target
		    <br /> demographics
		</div>
		<div class="content">
			<mat-accordion class="multi-select-expansion-panel">
			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <mat-panel-title>
			        Art & Design
			      </mat-panel-title>
			    </mat-expansion-panel-header>
			    <ul>
			    	<li>
			    		<a href="javascript:;" matRipple>Fine and Applied Arts</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>History</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>Literature in English</a>
			    	</li>
			    </ul>
			  </mat-expansion-panel>
			  <mat-expansion-panel [expanded]="true">
			    <mat-expansion-panel-header>
			      <mat-panel-title>
			        Sports
			      </mat-panel-title>
			    </mat-expansion-panel-header>
			    <ul>
			    	<li>
			    		<a href="javascript:;" class="selected" matRipple>Basketball</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>Soccer</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>Table Tennis</a>
			    	</li>
			    </ul>
			  </mat-expansion-panel>
			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <mat-panel-title>
			        Performing Arts
			      </mat-panel-title>
			    </mat-expansion-panel-header>
			    <ul>
			    	<li>
			    		<a href="javascript:;" class="selected" matRipple>Dance</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>Music</a>
			    	</li>
			    	<li>
			    		<a href="javascript:;" matRipple>Circus Arts</a>
			    	</li>
			    </ul>
			  </mat-expansion-panel>
			</mat-accordion>
		</div>
    </div>
  </div>
</div>