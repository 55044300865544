import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-carousel',
  templateUrl: './common-carousel.component.html',
  styleUrls: ['./common-carousel.component.scss']
})
export class CommonCarouselComponent implements OnInit {
  @Input() configData:any;
  // Slick Sliders==========
    slideConfigHomepageDflt = {
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button type="button" class="slick-prev home-default-slider-prev"><i class="far fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next home-default-slider-next"><i class="far fa-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  constructor() { 
  }

  ngOnInit(): void {
  }

}
