import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonCarouselComponent } from 'src/app/components/common-carousel/common-carousel.component';

@NgModule({
  declarations: [ CommonCarouselComponent],
  imports: [
    CommonModule,
    SlickCarouselModule
  ], 
  exports : [
    CommonCarouselComponent
  ]
})
export class CommonCarouselModule { }