import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SitemapComponent } from './sitemap/sitemap.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { SearchByFiltersComponent } from './pages/search-by-filters/search-by-filters.component';
import { CommonCarouselComponent } from './components/common-carousel/common-carousel.component';
import { CommonCarouselModule } from './components/common-carousel/common-carousel.module';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';

import { ClickOutsideDirective } from './clickoutside.directive';
import { PlyrModule } from 'ngx-plyr';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';

import { ClassAndCampComponent } from './pages/class-and-camp/class-and-camp.component';
import { RecordedComponent } from './pages/class-and-camp/recorded/recorded.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { SessionRosterComponent } from './pages/listings/session-roster/session-roster.component';
import { ManageLocationsComponent } from './pages/listings/manage-locations/manage-locations.component';

@NgModule({
  declarations: [
    AppComponent,
    SitemapComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    SearchByFiltersComponent,
    OnboardingComponent,
    ClickOutsideDirective,
    ClassAndCampComponent,
    RecordedComponent,
    ListingsComponent,
    SessionRosterComponent,
    ManageLocationsComponent
    // CommonCarouselComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatIconModule,
    NgxSliderModule,
    SlickCarouselModule,
    CommonCarouselModule,
    MatProgressBarModule,
    MatChipsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatPaginatorModule,
    PlyrModule,
    CrystalLightboxModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#2bae66",
      innerStrokeColor: "#6bc694",
      imageSrc: "assets/images/icons/v-ctrl.png",
      imageHeight: 10,
      imageWidth: 8,
      animationDuration: 300
    }),
    RouterModule.forRoot([
    {
        path: '',
        redirectTo: '/sitemap',
        pathMatch: 'full'
    },
    {
        path: 'sitemap',
        component: SitemapComponent
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'sign-in',
        component: SignInComponent
    },
    {
        path: 'sign-up',
        component: SignUpComponent
    },
    {
        path: 'forget-password',
        component: ForgetPasswordComponent
    },
    {
        path: 'wildster-search',
        component: SearchByFiltersComponent
    },
    {
        path: 'onboarding',
        component: OnboardingComponent
    },
    {
        path: 'class',
        redirectTo: 'class/once-a-week',
        pathMatch: 'full'
    },
    {
        path: 'class/once-a-week',
        component: ClassAndCampComponent
    },
    {
        path: 'class/one-time',
        component: ClassAndCampComponent
    },
    {
        path: 'class/multi-day',
        component: ClassAndCampComponent
    },
    {
        path: 'class/recorded',
        component: RecordedComponent
    },
    {
        path: 'listings',
        component: ListingsComponent
    },
    {
        path: 'listings/session-roster',
        component: SessionRosterComponent
    },
    {
        path: 'listings/manage-locations',
        component: ManageLocationsComponent
    }
], { relativeLinkResolution: 'legacy' })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
