<section class="listings-template">
	<div class="container">
		<!-- listing tabs/navs -->
		<div class="tab-navs-holder">
			<ul class="tab-navs">
				<li>
					<a href="javascript:;" matRipple class="active">
						Listings
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Transactions
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Manage Staff & Locations
					</a>
				</li>
				<li class="select-location">
					<a href="javascript:;" class="dropdown-ctrl" [ngClass]="{'menu-open': menuTrigger.menuOpen}" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()">
						<i class="fas fa-map-marker-alt"></i>
						1729 Majectic Dr STE 1, Lafayette, CO
						<i class="fas fa-chevron-down dropdown-icon"></i>
					</a>
			        <mat-menu class="dropdown-location-menu" #menu="matMenu" [overlapTrigger]="false" xPosition="before">
					    <span (mouseleave)="menuTrigger.closeMenu()">
					     <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Spring Full Drive, STE 1, Lafayett...
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
					    </span>
					</mat-menu>
				</li>
			</ul>
		</div>
		<!-- top btn-holder -->
		<div class="row top-btn-holder">			
			<div class="col-auto ml-auto">
				<button class="btn btn-create-listing" mat-button type="button">Create Listing</button>
			</div>
		</div>

		<!-- Manage Locations Panel -->
		<div class="expansion-panel-holder manage-locations-panel">
			<mat-accordion class="expansion-panel-listing">
			  <div class="panel-heading">
			  	<div class="flex-item">Location</div>
			  	<div class="flex-item">Phone</div>
			  </div>
			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="location">
			      		<h4>
			      			<span>Shredder: Indoor Ski & Snowboard School</span>
			      			<a href="javascript:;" class="edit visable-expanded">Edit</a>
			      		</h4>
			      		<p>
				      		1729 Majestic Dr STE 1,<br />
				      		Lafayette, CO 80026
				      	</p>
			      	</div>
			      	<div class="phone">
			      		<h4>(130) 3589-4475</h4>
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			    <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	 <table class="table table-borderless">
					  	<thead>
					  		<tr>
					  			<th>Staff Member</th>
					  			<th>Permissions</th>
					  			<th>
					  				<a href="javascript:;">
					  					Add Staff
					  				</a>
					  			</th>
					  		</tr>
					  	</thead>
					  	<tbody>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-green">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Jason Maestas</h4>
					  						<p>jason@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Owner</button>
					  				<button class="btn btn-tags" mat-button>Admin</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-purple">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Ritu Aurora</h4>
					  						<p>ritu@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Manage Locations</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-yellow">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Keith Griffith</h4>
					  						<p>keith@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Billing</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  	</tbody>
					  </table>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="location">
			      		<h4>
			      			<span>Shredder: Indoor Ski & Snowboard School</span>
			      			<a href="javascript:;" class="edit visable-expanded">Edit</a>
			      		</h4>
			      		<p>
				      		1729 Majestic Dr STE 1,<br />
				      		Lafayette, CO 80026
				      	</p>
			      	</div>
			      	<div class="phone">
			      		<h4>(130) 3589-4475</h4>
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			     <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	 <table class="table table-borderless">
					  	<thead>
					  		<tr>
					  			<th>Staff Member</th>
					  			<th>Permissions</th>
					  			<th>
					  				<a href="javascript:;">
					  					Add Staff
					  				</a>
					  			</th>
					  		</tr>
					  	</thead>
					  	<tbody>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-green">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Jason Maestas</h4>
					  						<p>jason@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Owner</button>
					  				<button class="btn btn-tags" mat-button>Admin</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-purple">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Ritu Aurora</h4>
					  						<p>ritu@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Manage Locations</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-yellow">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Keith Griffith</h4>
					  						<p>keith@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Billing</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  	</tbody>
					  </table>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="location">
			      		<h4>
			      			<span>Shredder: Indoor Ski & Snowboard School</span>
			      			<a href="javascript:;" class="edit visable-expanded">Edit</a>
			      		</h4>
			      		<p>
				      		1729 Majestic Dr STE 1,<br />
				      		Lafayette, CO 80026
				      	</p>
			      	</div>
			      	<div class="phone">
			      		<h4>(130) 3589-4475</h4>
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			     <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	 <table class="table table-borderless">
					  	<thead>
					  		<tr>
					  			<th>Staff Member</th>
					  			<th>Permissions</th>
					  			<th>
					  				<a href="javascript:;">
					  					Add Staff
					  				</a>
					  			</th>
					  		</tr>
					  	</thead>
					  	<tbody>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-green">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Jason Maestas</h4>
					  						<p>jason@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Owner</button>
					  				<button class="btn btn-tags" mat-button>Admin</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-purple">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Ritu Aurora</h4>
					  						<p>ritu@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Manage Locations</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-yellow">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Keith Griffith</h4>
					  						<p>keith@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Billing</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  	</tbody>
					  </table>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="location">
			      		<h4>
			      			<span>Shredder: Indoor Ski & Snowboard School</span>
			      			<a href="javascript:;" class="edit visable-expanded">Edit</a>
			      		</h4>
			      		<p>
				      		1729 Majestic Dr STE 1,<br />
				      		Lafayette, CO 80026
				      	</p>
			      	</div>
			      	<div class="phone">
			      		<h4>(130) 3589-4475</h4>
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			     <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	 <table class="table table-borderless">
					  	<thead>
					  		<tr>
					  			<th>Staff Member</th>
					  			<th>Permissions</th>
					  			<th>
					  				<a href="javascript:;">
					  					Add Staff
					  				</a>
					  			</th>
					  		</tr>
					  	</thead>
					  	<tbody>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-green">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Jason Maestas</h4>
					  						<p>jason@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Owner</button>
					  				<button class="btn btn-tags" mat-button>Admin</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-purple">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Ritu Aurora</h4>
					  						<p>ritu@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Manage Locations</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  		<tr>
					  			<td>
					  				<div class="flex-box">
					  					<div class="flex-icon access-yellow">
					  						<i class="fas fa-circle"></i>
					  					</div>
					  					<div>
					  						<h4>Keith Griffith</h4>
					  						<p>keith@shredderski.com</p>
					  					</div>
					  				</div>
					  			</td>
					  			<td>
					  				<button class="btn btn-tags" mat-button>Manage Classes</button>
					  				<button class="btn btn-tags" mat-button>Manage Staff</button>
					  				<button class="btn btn-tags" mat-button>View Transactions</button>
					  				<button class="btn btn-tags" mat-button>Billing</button>
					  			</td>
					  			<td>
					  				<button mat-icon-button [matMenuTriggerFor]="menuEditDropdown">
									  <mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu class="mat-cst-edit-dropdown" #menuEditDropdown="matMenu" xPosition="before">
									  <button mat-menu-item>
									    Edit
									  </button>
									  <button mat-menu-item>
									    Delete
									  </button>
									  <button mat-menu-item>
									    Deactivate
									  </button>
									</mat-menu>
					  			</td>
					  		</tr>
					  	</tbody>
					  </table>
			    </div>

			  </mat-expansion-panel>

			</mat-accordion>
		</div>
	</div>
</section>