import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';

@Component({
  selector: 'app-class-and-camp',
  templateUrl: './class-and-camp.component.html',
  styleUrls: ['./class-and-camp.component.scss']
})
export class ClassAndCampComponent implements OnInit {

  	constructor(public router: Router) {}
  	
  	// get the component instance to have access to plyr instance
	@ViewChild(PlyrComponent)
	plyr: PlyrComponent;

	// or get it from plyrInit event
	player: Plyr;

	videoSources: Plyr.Source[] = [
	  {
	 	src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
	  	type: 'video/mp4',
	  },
	];
	options: Plyr.Options = {		
		autoplay: true,
		muted: true,
		loop: { active: true },
		controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
	}
	poster = 'assets/images/placeholder/lightbox-1.jpg';

	ngOnInit(): void {
	    // Add class to specify this page=============
	  	const body = document.getElementsByTagName('body')[0];
	    body.classList.add('class-n-camp-layout');
	}
	ngOnDestroy(): void {
	    const body = document.getElementsByTagName('body')[0];
	    body.classList.remove('class-n-camp-layout');
	}

}
