<nav class="navbar navbar-main">
  <div class="container nav-flex-box">
    <div class="logo-holder">
      <a class="navbar-brand logo" routerLink="/">
        Wildster
      </a>
      <div class="search-box hidden-xs">
        <a href="javascript:;" class="btn-search">
          <i class="far fa-search"></i>
        </a>          
        <input type="text" class="input-search form-control" [(ngModel)]="value" (click)="opened = !opened">
        <a href="javascript:;" class="clear-btn" *ngIf="value">
          <i class="fal fa-times" aria-label="Clear" (click)="value=''"></i>
        </a>
        <a href="javascript:;" class="mc-btn">
          <img src="assets/images/icons/microphone.png" alt="">
        </a>
        <div class="search-dropdown" *ngIf="opened" (clickOutside)="opened = false">
          <div class="scrollable-box">
            <ul>
              <li>
                <a href="javascript:;">
                  <i class="far fa-search search-icon-xs"></i>
                  Learn <strong>Guitar</strong> from beginner to pro
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="far fa-search search-icon-xs"></i>
                  <strong>Guitar</strong> playing for kids
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="far fa-search search-icon-xs"></i>
                  Play <strong>guitar</strong> like pro
                </a>
              </li>               
              <li>
                <a href="javascript:;">
                  <i class="far fa-search search-icon-xs"></i>
                  <strong>Guitar</strong> learning make easy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-nav-holder">      
      <ul class="main-nav">
        <li routerLinkActive="active" class="hidden-xs">
          <a href="javascript:;" mat-button>Teachers</a>
        </li>
        <li routerLinkActive="active" class="hidden-xs">
          <a href="javascript:;" mat-button>Help</a>
        </li>
        <li routerLinkActive="active">
          <a routerLink="/sign-in" mat-button>Log in</a>
        </li>
        <li>
          <a routerLink="/sign-up" mat-flat-button class="signup-btn">Join for free</a>
        </li>
      </ul>
    </div>
  </div>
</nav>