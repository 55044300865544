<section class="listings-template">
	<div class="container">
		<!-- listing tabs/navs -->
		<div class="tab-navs-holder">
			<ul class="tab-navs">
				<li>
					<a href="javascript:;" matRipple class="active">
						Listings
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Transactions
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Manage Staff & Locations
					</a>
				</li>
				<li class="select-location">
					<a href="javascript:;" class="dropdown-ctrl" [ngClass]="{'menu-open': menuTrigger.menuOpen}" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()">
						<i class="fas fa-map-marker-alt"></i>
						1729 Majectic Dr STE 1, Lafayette, CO
						<i class="fas fa-chevron-down dropdown-icon"></i>
					</a>
			        <mat-menu class="dropdown-location-menu" #menu="matMenu" [overlapTrigger]="false" xPosition="before">
					    <span (mouseleave)="menuTrigger.closeMenu()">
					     <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Spring Full Drive, STE 1, Lafayett...
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
					    </span>
					</mat-menu>
				</li>
			</ul>
		</div>
		<!-- Session header content -->
		<div class="row session-header-content">
			<div class="col-auto cnt-left mr-auto">
				<p>
					Let's Travel the World! - South America 
					<i class="fas fa-chevron-double-right"></i>
					January 6 – April 12
				</p>
				<h1>
					January 6 – April 12
					<a href="javascript:;" matRipple>M</a>
					<a href="javascript:;" matRipple>W</a>
					<a href="javascript:;" matRipple>F</a>
				</h1>
				<ul>
					<li>1 – 2:45 pm MST</li>
					<li>14 days</li>
				</ul>
				<div class="input-box">
					<a href="javascript:;" class="calendar-icon" (click)="picker.open()">
    					<i class="far fa-calendar-alt"></i>
    				</a>
					<input type="text" class="form-control" [matDatepicker]="picker" [formControl]="date">
					<mat-datepicker #picker></mat-datepicker>
				</div>
			</div>
			<div class="col-auto cnt-right">
				<ul>
					<li>
						<a href="javascript:;">Send Message</a>
					</li>
					<li>
						<a href="javascript:;">View Classroom</a>
					</li>
				</ul>
				<div class="inline-boxs">
					<div class="box">
						<p>Enrolled</p>
						<h4>20 <small>Students</small></h4>
					</div>
					<div class="box">
						<p>Disenrolled</p>
						<h4>2 <small>Students</small></h4>						 
					</div>
				</div>
			</div>
		</div>
		<!-- Session Content -->
		<div class="session-content">
			<div class="filter-bay-name session-mat-design">
				<mat-form-field class="mat-block">
				  <mat-label>Filter By Name</mat-label>
				  <input matInput  placeholder="">
				</mat-form-field>
			</div>
			<div class="content-box paginator-design">
				<div class="table-holder">
					<div class="table-responsive">
					  <table class="table table-borderless table-striped">
					  	<thead>
					  		<tr>
					  			<th width="20%">Student Name</th>
					  			<th width="20%">Age <i class="fas fa-long-arrow-up sort-icon"></i></th>
					  			<th width="20%">Status</th>
					  			<th width="40%">Attendance</th>
					  		</tr>
					  	</thead>
					    <tbody>
					    	<tr>
					    		<td width="20%">Jason Maestas</td>
					    		<td width="20%">8 years</td>
					    		<td width="20%">Enrolled</td>
					    		<td width="40%" class="attendance absent">
					    			<i class="fas fa-circle"></i>
					    		</td>
					    	</tr>
					    	<tr>
					    		<td width="20%">Rahul Arora</td>
					    		<td width="20%">9 years</td>
					    		<td width="20%">Enrolled</td>
					    		<td width="40%" class="attendance">
					    			<i class="fas fa-circle"></i>
					    		</td>
					    	</tr>
					    	<tr>
					    		<td width="20%">Jason Maestas</td>
					    		<td width="20%">8 years</td>
					    		<td width="20%">Disenrolled</td>
					    		<td width="40%" class="attendance">
					    			<i class="fas fa-circle"></i>
					    		</td>
					    	</tr>					    	
					    	<tr>
					    		<td width="20%">Rahul Arora</td>
					    		<td width="20%">9 years</td>
					    		<td width="20%">Enrolled</td>
					    		<td width="40%" class="attendance">
					    			<i class="fas fa-circle"></i>
					    		</td>
					    	</tr>
					    </tbody>
					  </table>
					</div>
					<div class="mat-paginator-holder">
						<mat-paginator [length]="100"
						              [pageSize]="10"
						              [pageSizeOptions]="[5, 10, 25, 100]">
						</mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>