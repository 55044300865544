<footer>
	<div class="container">		
		<div class="row justify-content-md-center logo-holder">
			<div class="col-md-11">
				<a routerLink="/home">
			        Wildster
			    </a>
			</div>
		</div>
		<div class="row justify-content-md-center">	
			<div class="col-md-11">	
				<div class="row footer-content">
					<div class="col-6 col-sm-auto">
						<h4>MENU</h4>
						<ul>
							<li><a href="javascript:;">Teachers</a></li>
							<li><a href="javascript:;">Events</a></li>
							<li><a href="javascript:;">Blog</a></li>
							<li><a href="javascript:;">Help</a></li>
							<li><a href="javascript:;">Log in</a></li>
						</ul>
					</div>	
					<div class="col-6 col-sm-auto">
						<h4>RESOURCES</h4>
						<ul>
							<li><a href="javascript:;">Status</a></li>
							<li><a href="javascript:;">Why Wildster?</a></li>
							<li><a href="javascript:;">Help Center</a></li>
						</ul>
					</div>
					<div class="col-6 col-sm-auto">
						<h4>COMPANY</h4>
						<ul>
							<li><a href="javascript:;">About</a></li>
							<li><a href="javascript:;">Careers</a></li>
							<li><a href="javascript:;">End User Terms</a></li>
							<li><a href="javascript:;">Retailer Terms</a></li>
						</ul>
					</div>
					<div class="col-6 col-sm-auto">
						<h4>BUSINESS</h4>
						<ul>							
							<li><a href="javascript:;">Partners</a></li>
							<li><a href="javascript:;">Integration</a></li>
							<li><a href="javascript:;">Speak With Us</a></li>
							<li><a href="javascript:;">Privacy policy</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="clearfix copyright-holder">
			<div class="row align-items-center">
				<div class="col-lg-auto mr-auto order-2 order-lg-1">
					&copy; 2020 Wildster, LLC.  
					<span class="hidden-xs">&nbsp;</span> • <span class="hidden-xs">&nbsp;</span> 
					<a href="javascript:;" target="_blank">Terms and Conditions</a>  
					<span class="hidden-xs">&nbsp;</span> • <span class="hidden-xs">&nbsp;</span>
					<a href="javascript:;" target="_blank">Privacy Policy</a>
				</div>
				<div class="col-lg-auto social-bar order-1 order-lg-2">
					<span class="follow-us">Follow us</span>
					<a href="javascript:;" target="_blank">
						<i class="fab fa-facebook-square"></i>
					</a>
					<a href="javascript:;" target="_blank">
						<i class="fab fa-twitter-square"></i>
					</a>
					<a href="javascript:;" target="_blank">
						<i class="fab fa-youtube-square"></i>
					</a>
					<a href="javascript:;" target="_blank">
						<i class="fab fa-linkedin"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</footer>