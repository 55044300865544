<section class="hero-box">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-6 col-lg-5 hero-content">
				<h1 class="title hidden-xs">
					Classes & Camps for
					Kids Ages 3 – 18
				</h1>
				<!-- Title sec for mobile -->
				<h1 class="title visible-xs">
					Wildster
				</h1>
				<p class="subtitle visible-xs">
					Classes for Kids & Adults
				</p>
				<!-- // End title sec for mobile -->

				<div class="search-box hidden-xs">
					<a href="javascript:;" class="btn-search">
						<i class="far fa-search"></i>
					</a>					
					<input type="text" class="input-search form-control" [(ngModel)]="value" (click)="opened = !opened">
					<a href="javascript:;" class="clear-btn" *ngIf="value">
						<i class="fal fa-times" aria-label="Clear" (click)="value=''"></i>
					</a>
					<a href="javascript:;" class="mc-btn">
						<img src="assets/images/icons/microphone.png" alt="">
					</a>
					<div class="search-dropdown" *ngIf="opened" (clickOutside)="opened = false">
						<div class="scrollable-box">
							<ul>
								<li>
									<a href="javascript:;">
										<i class="far fa-search search-icon-xs"></i>
										Learn <strong>Guitar</strong> from beginner to pro
									</a>
								</li>
								<li>
									<a href="javascript:;">
										<i class="far fa-search search-icon-xs"></i>
										<strong>Guitar</strong> playing for kids
									</a>
								</li>
								<li>
									<a href="javascript:;">
										<i class="far fa-search search-icon-xs"></i>
										Play <strong>guitar</strong> like pro
									</a>
								</li>								
								<li>
									<a href="javascript:;">
										<i class="far fa-search search-icon-xs"></i>
										<strong>Guitar</strong> learning make easy
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- mobile search -->
				<div class="search-box visible-xs" data-toggle="modal" data-target="#modal-mobile-search">
					<a href="javascript:;" class="btn-search">
						<i class="fas fa-search"></i>
					</a>					
					<input type="text" class="input-search form-control">
					<a href="javascript:;" class="mc-btn">
						<img src="assets/images/icons/microphone.png" alt="">
					</a>
				</div>
				<!-- // End mobile search -->
				<div class="flex-box hidden-xs">
					<div class="box">
						<a href="javascript:;">
							<div class="icon-holder">
								<img src="assets/images/icons/live-online-classes.png" alt="">
							</div>
							<h4>Live Online Classes</h4>
						</a>
					</div>
					<div class="box">
						<a href="javascript:;">
							<div class="icon-holder">
								<img src="assets/images/icons/local-classes.png" alt="">
							</div>
							<h4>Local Classes</h4>
						</a>
					</div>
					<div class="box">
						<a href="javascript:;">
							<div class="icon-holder">
								<img src="assets/images/icons/summer-camps.png" alt="">
							</div>
							<h4>Summer Camps</h4>
						</a>
					</div>
					<div class="box">
						<a href="javascript:;">
							<div class="icon-holder">
								<img src="assets/images/icons/events-parties.png" alt="">
							</div>
							<h4>Events & Parties</h4>
						</a>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-lg-7 banner-holder">
				<img src="assets/images/home-top-banner.png" alt="" />
			</div>			
		</div>	

		<!-- Flex box mobile -->
		<div class="row flex-box visible-xs">
			<div class="box">
				<a href="javascript:;">
					<div class="icon-holder">
						<img src="assets/images/icons/live-online-classes.png" alt="">
					</div>
					<h4>Live Online Classes</h4>
				</a>
			</div>
			<div class="box">
				<a href="javascript:;">
					<div class="icon-holder">
						<img src="assets/images/icons/local-classes.png" alt="">
					</div>
					<h4>Local Classes</h4>
				</a>
			</div>
			<div class="box">
				<a href="javascript:;">
					<div class="icon-holder">
						<img src="assets/images/icons/summer-camps.png" alt="">
					</div>
					<h4>Summer Camps</h4>
				</a>
			</div>
			<div class="box">
				<a href="javascript:;">
					<div class="icon-holder">
						<img src="assets/images/icons/events-parties.png" alt="">
					</div>
					<h4>Events & Parties</h4>
				</a>
			</div>
		</div>
		<!-- Announcement Mobile-->
		<div class="announcement-holder visible-xs" *ngIf="showAnnouncement">			
			<a href="javascript:;" class="ansmnt-close-btn" (click)="toggleAnnouncement()">
				<i class="fal fa-times"></i>
			</a>
			<h4>COVID-19 Announcement</h4>
			<p>
				If you need assistance, you can enroll to ski this month’s payment without incurring interest charges.
			</p>
			<a href="javascript:;" class="learn-more-btn">
				Learn more
			</a>
		</div>
	</div>
</section>

<!-- Filter Tags -->
<section class="panel-with-filter-tags">
	<div class="container">
		<div class="tags-holder">
			<div class="tags-btn-holder">
				<button mat-flat-button class="btn btn-tag">
					Drawing
				</button>
				<button mat-flat-button class="btn btn-tag">
					Cooking
				</button>
				<button mat-flat-button class="btn btn-tag">
					Art
				</button>
				<button mat-flat-button class="btn btn-tag">
					Programming
				</button>
				<button mat-flat-button class="btn btn-tag">
					Minecraft
				</button>
				<button mat-flat-button class="btn btn-tag">
					Spanish
				</button>
				<button mat-flat-button class="btn btn-tag">
					Math
				</button>
				<button mat-flat-button class="btn btn-tag">
					School Subjects
				</button>
				<button mat-flat-button class="btn btn-tag">
					Photography
				</button>
				<button mat-flat-button class="btn btn-tag">
					Dance
				</button>
			</div>
			<div class="more-categories-holder hidden-xs">
				<a href="javascript:;" (click)="toggleCategories()" class="btn-toggle-cate">
					<span *ngIf="!showCategories">
						More Categories
					    <i class="fas fa-chevron-down"></i>
					</span>
					<span *ngIf="showCategories">
						<span class="has-uline">Hide Categories</span>
					    <i class="fas fa-chevron-up"></i>
					</span>
				</a>

				<div *ngIf="showCategories" class="moretag-dropdown" (clickOutside)="showCategories = false">
			        <button mat-flat-button class="btn btn-tag">
						Photography
					</button>
					<button mat-flat-button class="btn btn-tag">
						Fine Art
					</button>
					<button mat-flat-button class="btn btn-tag">
						Dance
					</button>
					<button mat-flat-button class="btn btn-tag">
						Drawing
					</button>
					<button mat-flat-button class="btn btn-tag">
						Cooking
					</button>
					<button mat-flat-button class="btn btn-tag">
						Art
					</button>
					<button mat-flat-button class="btn btn-tag">
						Programming
					</button>					
					<button mat-flat-button class="btn btn-tag">
						Art
					</button>
					<button mat-flat-button class="btn btn-tag">
						Minecraft
					</button>
					<button mat-flat-button class="btn btn-tag">
						Spanish
					</button>
					<button mat-flat-button class="btn btn-tag">
						Math
					</button>					
					<button mat-flat-button class="btn btn-tag">
						Art
					</button>
					<button mat-flat-button class="btn btn-tag">
						School Subjects
					</button>
					<button mat-flat-button class="btn btn-tag">
						Drawing
					</button>
					<button mat-flat-button class="btn btn-tag">
						Cooking
					</button>
					<button mat-flat-button class="btn btn-tag">
						Art
					</button>
					<button mat-flat-button class="btn btn-tag">
						Programming
					</button>
					<button mat-flat-button class="btn btn-tag">
						Minecraft
					</button>
					<button mat-flat-button class="btn btn-tag">
						Spanish
					</button>
					<button mat-flat-button class="btn btn-tag">
						Math
					</button>
					<button mat-flat-button class="btn btn-tag">
						School Subjects
					</button>
			    </div>
			</div>
		</div>
		<!-- Live Online Classes & Camps -->
		<div class="row slide-holder default-slider-holder hidden-xs">
			<div class="col-12 title-holder">
				<h1>Live Online Classes & Camps</h1>
				<p>Taught by qualified teachers over live video for as low as $5.</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>		

		<!-- Classes near Boulder, CO -->
		<div class="row slide-holder default-slider-holder hidden-xs">
			<div class="col-12 title-holder">
				<h1>
					Classes near Boulder, CO 
					<a href="javascript:;"><i class="fal fa-map-marker-alt"></i></a>
				</h1>
				<p>Taught by qualified teachers over live video for as low as $5.</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>

		<!-- =============Mobile Panel=================== -->
		<div class="browse-by-act visible-xs">
			<h1>Browse by activity type</h1>
			<div class="row flex-box">
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Snowsports</h4>
					</a>
				</div>
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Performing Arts</h4>
					</a>
				</div>
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Snowsports</h4>
					</a>
				</div>
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Performing Arts</h4>
					</a>
				</div>
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Snowsports</h4>
					</a>
				</div>
				<div class="box">
					<a href="javascript:;">
						<div class="img-holder">
							<img src="assets/images/placeholder/browse-by-act.jpg" alt="">
						</div>
						<h4>Performing Arts</h4>
					</a>
				</div>
			</div>
		</div>
		<div class="top-rated-online-classes visible-xs">
			<h1 class="title">
				Top rated in Broomfield
			</h1>
			<div class="row online-classes">
				<div class="col-6 box">
					<a href="javascript:;">
					  	<div class="thumbnail-holder">	
						  	<img src="assets/images/placeholder/online-class-mobile-1.png" alt="" />
						</div>
						<div class="content">
							<p class="status">BOULDER</p>
							<!-- progress circle -->							
							<div class="progress-circle">
					  			3.5
					  		</div>	
							<h4>Eastern Bunny & Egg Extravaganzha...</h4>
							<p class="auth">By Samanta Nath</p>
							<div class="cont-ftr">			
								<div class="item-left-badge">4 spots left</div>		
								<div class="byage">Ages 5 – 12</div>
							</div>
						</div>
					</a>
				</div>
				<div class="col-6 box">
					<a href="javascript:;">
					  	<div class="thumbnail-holder">	
						  	<img src="assets/images/placeholder/online-class-mobile-2.png" alt="" />
						</div>
						<div class="content">
							<p class="status">BOULDER</p>
							<!-- progress circle -->							
							<div class="progress-circle">
					  			3.5
					  		</div>	
							<h4>Eastern Bunny & Egg Extravaganzha...</h4>
							<p class="auth">By Samanta Nath</p>
							<div class="cont-ftr">			
								<div class="item-left-badge">4 spots left</div>		
								<div class="byage">Ages 5 – 12</div>
							</div>
						</div>
					</a>
				</div>
				<div class="col-6 box">
					<a href="javascript:;">
					  	<div class="thumbnail-holder">	
						  	<img src="assets/images/placeholder/online-class-mobile-1.png" alt="" />
						</div>
						<div class="content">
							<p class="status">BOULDER</p>
							<!-- progress circle -->							
							<div class="progress-circle">
					  			3.5
					  		</div>	
							<h4>Eastern Bunny & Egg Extravaganzha...</h4>
							<p class="auth">By Samanta Nath</p>
							<div class="cont-ftr">			
								<div class="item-left-badge">4 spots left</div>		
								<div class="byage">Ages 5 – 12</div>
							</div>
						</div>
					</a>
				</div>
				<div class="col-6 box">
					<a href="javascript:;">
					  	<div class="thumbnail-holder">	
						  	<img src="assets/images/placeholder/online-class-mobile-2.png" alt="" />
						</div>
						<div class="content">
							<p class="status">BOULDER</p>
							<!-- progress circle -->							
							<div class="progress-circle">
					  			3.5
					  		</div>	
							<h4>Eastern Bunny & Egg Extravaganzha...</h4>
							<p class="auth">By Samanta Nath</p>
							<div class="cont-ftr">			
								<div class="item-left-badge">4 spots left</div>		
								<div class="byage">Ages 5 – 12</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="btn-holder visible-xs">
			<button type="button" mat-flat-button class="btn btn-explore">
				Explore Broomfield
			</button>
		</div>
		<!-- ============= End Mobile Panel=================== -->
	</div>
</section>

<!-- Lots to Learn -->
<section class="lots-to-learn-section">
	<div class="container">
		<div class="lots-to-learn-title-holder">
			<h1>Lots to learn</h1>
			<p class="hidden-xs">
				Let’s Sed ut perspiciatis unde omnis iste natus error sitvoluptatem
				<br /> accusantium doloremque ntium totam rem.
			</p>
			<p class="visible-xs">
				Let’s Sed ut perspiciatis unde omnis iste natus error sitvoluptatem
				accusantium doloremque ntium totam rem.
			</p>
		</div>
		<!-- Category Tabs with Slider -->
		<div class="row ltl-tab-holder">
		  <div class="col-md-4 col-lg-4 col-xl-3 nav-pills-holder">
		    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
		      <a class="nav-link" mat-flat-button id="v-pills-01-tab" data-toggle="pill" href="#v-pills-01" role="tab" aria-controls="v-pills-01" aria-selected="false">
		      	Art & Design
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-02-tab" data-toggle="pill" href="#v-pills-02" role="tab" aria-controls="v-pills-02" aria-selected="false">
		      	Sports
		      </a>
		      <a class="nav-link active" mat-flat-button id="v-pills-03-tab" data-toggle="pill" href="#v-pills-03" role="tab" aria-controls="v-pills-03" aria-selected="true">
		      	Coding & Tech
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-04-tab" data-toggle="pill" href="#v-pills-04" role="tab" aria-controls="v-pills-04" aria-selected="false">
		      	Music
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-05-tab" data-toggle="pill" href="#v-pills-05" role="tab" aria-controls="v-pills-05" aria-selected="false">
		      	Performing Arts
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-06-tab" data-toggle="pill" href="#v-pills-06" role="tab" aria-controls="v-pills-06" aria-selected="false">
		      	English
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-07-tab" data-toggle="pill" href="#v-pills-07" role="tab" aria-controls="v-pills-07" aria-selected="false">
		      	Health & Wellness & Recreation
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-08-tab" data-toggle="pill" href="#v-pills-08" role="tab" aria-controls="v-pills-08" aria-selected="false">
		      	Life Skills
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-09-tab" data-toggle="pill" href="#v-pills-09" role="tab" aria-controls="v-pills-09" aria-selected="false">
		      	Health & Wellness
		      </a>
		      <a class="nav-link" mat-flat-button id="v-pills-10-tab" data-toggle="pill" href="#v-pills-10" role="tab" aria-controls="v-pills-10" aria-selected="false">
		      	Life Skills
		      </a>
		    </div>
		  </div>
		  <div class="col-md-8 col-lg-8 col-xl-9 tab-content-holder">
		    <div class="tab-content" id="v-pills-tabContent">

		      <div class="tab-pane fade" id="v-pills-01" role="tabpanel" aria-labelledby="v-pills-01-tab">
		      	<div class="row category-slide-holder">
		      		<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
		      </div>

		      <div class="tab-pane fade" id="v-pills-02" role="tabpanel" aria-labelledby="v-pills-02-tab">
			      <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">					
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade show active" id="v-pills-03" role="tabpanel" aria-labelledby="v-pills-03-tab">
			    <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-04" role="tabpanel" aria-labelledby="v-pills-04-tab">
			      <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-05" role="tabpanel" aria-labelledby="v-pills-05-tab">
			    <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-06" role="tabpanel" aria-labelledby="v-pills-06-tab">
			      <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-07" role="tabpanel" aria-labelledby="v-pills-07-tab">
			    <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-08" role="tabpanel" aria-labelledby="v-pills-08-tab">
			      <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-09" role="tabpanel" aria-labelledby="v-pills-09-tab">
			    <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		      <div class="tab-pane fade" id="v-pills-10" role="tabpanel" aria-labelledby="v-pills-10-tab">
			      <div class="row category-slide-holder">
					<ngx-slick-carousel class="col-12 category-slider" #slickModal="slick-carousel" 
                        [config]="slideConfigHomepageCategory">	
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/guitar.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Guitar</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/reading.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Reading</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/dancing.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m3.jpg" class="visible-xs" alt="" />
						  		<h4>Dance</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/algebra.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m4.jpg" class="visible-xs" alt="" />
						  		<h4>Algebra</h4>
							</a>
						  </div>
						</div>
						<div ngxSlickItem>
						  <div class="box">
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/snowboarding.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m2.jpg" class="visible-xs" alt="" />
						  		<h4>Snowboarding</h4>
							</a>
						  	<a href="javascript:;">
						  		<img src="assets/images/placeholder/politics.jpg" class="hidden-xs" alt="" />
						  		<!-- mobile banner -->
						  		<img src="assets/images/placeholder/lots-to-lrn-m1.jpg" class="visible-xs" alt="" />
						  		<h4>Politics</h4>
							</a>
						  </div>
						</div>
					</ngx-slick-carousel>
				</div>
			  </div>

		    </div>
		  </div>
		</div>

		<!-- Events & parties near Boulder. CO -->
		<div class="row slide-holder default-slider-holder hidden-xs">
			<div class="col-12 title-holder">
				<h1>
					Events & parties near Boulder. CO 
					<a href="javascript:;"><i class="fal fa-map-marker-alt"></i></a>
				</h1>
				<p>Enjoy the local event and parties near Boulder.CO Its around 25 KM</p>
			</div>
			<app-common-carousel class="col-12 slide-box"></app-common-carousel>
		</div>
	</div>
</section>

<!-- Meet our Teachers -->
<section class="meet-our-teachers visible-xs">
	<div class="container">
		<h1 class="title">
			Meet our Teachers
		</h1>
		<div class="row our-teachers">
			<div class="col-6 box">
				<a href="javascript:;">
				  	<div class="thumbnail-holder">	
					  	<img src="assets/images/placeholder/teacher-1.jpg" alt="" />
					</div>
					<div class="content">
						<!-- progress circle -->							
						<div class="progress-circle">
				  			3.5
				  		</div>	
						<h4>Samantha Nath</h4>
						<p>B.Ed, Phd</p>
					</div>
				</a>
			</div>
			<div class="col-6 box">
				<a href="javascript:;">
				  	<div class="thumbnail-holder">	
					  	<img src="assets/images/placeholder/teacher-2.jpg" alt="" />
					</div>
					<div class="content">
						<!-- progress circle -->							
						<div class="progress-circle">
				  			3.5
				  		</div>	
						<h4>Astron Honda</h4>
						<p>B.Ed, Phd</p>
					</div>
				</a>
			</div>
			<div class="col-6 box">
				<a href="javascript:;">
				  	<div class="thumbnail-holder">	
					  	<img src="assets/images/placeholder/teacher-3.jpg" alt="" />
					</div>
					<div class="content">
						<!-- progress circle -->							
						<div class="progress-circle">
				  			3.5
				  		</div>	
						<h4>Naresh Singh</h4>
						<p>B.Ed, Phd</p>
					</div>
				</a>
			</div>
			<div class="col-6 box">
				<a href="javascript:;">
				  	<div class="thumbnail-holder">	
					  	<img src="assets/images/placeholder/teacher-4.jpg" alt="" />
					</div>
					<div class="content">
						<!-- progress circle -->							
						<div class="progress-circle">
				  			3.5
				  		</div>	
						<h4>Neha Sharma</h4>
						<p>B.Ed, Phd</p>
					</div>
				</a>
			</div>

		</div>
	</div>
</section>

<!-- Mobile search modal -->
<div class="modal modal-mobile-search fade" id="modal-mobile-search" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">	  
    	<div class="search-box-holder">
    		<a href="javascript:;" class="back-btn" data-dismiss="modal" aria-label="Close">    		
	      		<i class="far fa-long-arrow-left"></i>
		    </a>
	      	<div class="search-box">				
				<input type="text" class="input-search form-control" [(ngModel)]="value">
				<a href="javascript:;" class="clear-btn" *ngIf="value">
					<i class="fal fa-times" aria-label="Clear" (click)="value=''"></i>
				</a>
				<a href="javascript:;" class="mc-btn">
					<img src="assets/images/icons/microphone.png" alt="">
				</a>
			</div>
		</div>
		<div class="search-dropdown" *ngIf="value">
			<ul>
				<li>
					<a href="javascript:;">
						<i class="far fa-search search-icon-xs"></i>
						Ski
					</a>
					<a href="javascript:;" class="clear-btn">
						<i class="fal fa-times"></i>
					</a>
				</li>
				<li>
					<a href="javascript:;">
						<i class="far fa-search search-icon-xs"></i>
						Skiing
					</a>
					<a href="javascript:;" class="clear-btn">
						<i class="fal fa-times"></i>
					</a>
				</li>
				<li>
					<a href="javascript:;">
						<i class="far fa-search search-icon-xs"></i>
						Ski
					</a>
					<a href="javascript:;" class="clear-btn">
						<i class="fal fa-times"></i>
					</a>
				</li>								
				<li>
					<a href="javascript:;">
						<i class="far fa-search search-icon-xs"></i>
						Skiing
					</a>
					<a href="javascript:;" class="clear-btn">
						<i class="fal fa-times"></i>
					</a>
				</li>
			</ul>
		</div>

    </div>
  </div>
</div>