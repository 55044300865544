<div class="class-n-camp-template">
	<!-- wildster lightbox -->
	<div class="wildster-light-box-holder">
		<div class="container-md">
			<div class="flex-box" lightbox-group>
				<!-- Video -->
				<div class="flex-item video-holder">
					<div mat-card-image plyr [plyrOptions]="options" class="full-hgt-vdo-player" 
      					 [plyrSources]="videoSources" [plyrPoster]="poster"></div>
				</div>
				<!-- Images -->
				<div class="flex-item img-grd-f-2">
					<div class="item">
						<img src="assets/images/placeholder/lightbox-2.jpg" lightbox [counter]="true"
    	counterSeparator=" of " backgroundColor="white" [fullImage]="{path: 'assets/images/placeholder/lightbox-2x.jpg' }" alt="">
					</div>
					<div class="item">
						<img src="assets/images/placeholder/lightbox-3.jpg" lightbox [counter]="true"
    	counterSeparator=" of " backgroundColor="white" [fullImage]="{path: 'assets/images/placeholder/lightbox-3x.jpg' }" alt="">
					</div>
				</div>
				<div class="flex-item img-sngl-item">
					<img src="assets/images/placeholder/lightbox-4.jpg" lightbox [counter]="true"
    	counterSeparator=" of " backgroundColor="white" [fullImage]="{path: 'assets/images/placeholder/lightbox-4x.jpg' }" alt="">
				</div>
				<div class="flex-item img-sngl-item">
					<img src="assets/images/placeholder/lightbox-5.jpg" lightbox [counter]="true"
    	counterSeparator=" of " backgroundColor="white" [fullImage]="{path: 'assets/images/placeholder/lightbox-5x.jpg' }" alt="">
				</div>
			</div>
		</div>
	</div>	

	<!-- Main Contents -->
	<div class="content-holder">
		<div class="container">
			<!-- video titles and summary -->
			<div class="row cont-header">
				<div class="col-md-8 col-lg title-holder">
					<!-- status for mobile only -->
					<p class="status visible-xs">Online</p>
					<!-- status desktop -->
					<p class="status hidden-xs">CLASS • BOULDER, CO</p>
					<h1 class="title">
						Novel Study: Fever 1793 -
						Twice Weekly Version
					</h1>
					<p class="author">
						<span>by Shredder Ski</span> 
						<a href="javascript:;" class="more-location hidden-xs">2 more locations <i class="fas fa-angle-down"></i></a>
					</p>
					<p class="save-n-share hidden-xs">						
						<a href="javascript:;">
							<i class="far fa-bookmark"></i>
							Save
						</a>
						<a href="javascript:;">
							<i class="far fa-share-alt"></i>
							Share
						</a>
					</p>
					<!-- reviews for mobile -->
					<div class="review-box visible-xs">
						<div class="review-circle">
							3.5
						</div>
						<p>3.5 out of 5</p>
					</div>
				</div>
				<!-- badge and opinions for desktop version -->
				<div class="col-lg-12 col-xl ml-xl-auto hidden-xs">
					<div class="badge-holder class">
						<div class="flx-box age-n-duratioin wider">
							<div class="iner-flex has-bdr-btm">
								<div class="col cnt">
									<span>Age</span><br />
									2 – 5 years
								</div>
								<div class="col cnt">
									<span>Class</span><br />
									1 class
								</div>
							</div>
							<div class="iner-flex">
								<div class="col cnt">
									<span>Duration</span><br />
									45 minutes
								</div>
								<div class="col cnt">
									<span>Frequency</span><br />
									One time
								</div>
							</div>
						</div>
						<div class="flx-box opinions">
							<circle-progress
							  [percent]="78"
							  [radius]="60"
							  [maxPercent]="100"
							  [outerStrokeWidth]="7"
							  [innerStrokeWidth]="7"
							  [space]="-7"
							  [showBackground]="false"
							  [showSubtitle]="false"
							  [showUnits]="false"
							  [clockwise]="false"
							  [title]="4.1"
							  [outerStrokeColor]="'#fff'"
							  [titleColor]="'#fff'"
							  [titleFontWeight]="600"
							  [titleFontSize]="40"
							></circle-progress>
							<h5>300 opinions</h5>
						</div>
						<div class="flx-box badge-box">
							<img src="assets/images/badge.png" alt="">
						</div>		
					</div>			
				</div>
				<!-- Age and duration for mobile -->
				<div class="col-12 visible-xs">
					<div class="row age-n-duratioin-xs">
						<div class="col-6">							
							<div class="box">
								<span>Age</span><br />
								2 – 5 years
							</div>
						</div>
						<div class="col-6">							
							<div class="box">
								<span>Duration</span><br />
								45 minutes
							</div>
						</div>
						<div class="col-6">
							<div class="box">
								<span>Class</span><br />
								1 Class
							</div>
						</div>
						<div class="col-6">
							<div class="box">
								<span>Frequency</span><br />
								One time
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Main Content Box -->
			<div class="row main-content-box">
				<div class="content-left">
					<!-- Short description -->
					<div class="short-description">
						<h1>What the kids will do</h1>
						<p>
							This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
						</p>
						<p>
							First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests. These baaaad boys have appalling table manners, so you might like to join them with your own snacks.
						</p>
					</div>
					<!-- full description -->
					<div class="full-description">
						<h1>Things to know</h1>
						<mat-accordion class="dflt-description-expansion-panel">
						  <mat-expansion-panel #exp1>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Itinerary
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp1.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp1.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp2>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Learning Goals
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp2.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp2.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel [expanded]="true" #exp3>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Homework
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp3.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp3.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp4>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Supplies Needed
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp4.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp4.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						  <mat-expansion-panel #exp5>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        External Resources
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp5.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp5.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>						  
						  <mat-expansion-panel #exp6>
						    <mat-expansion-panel-header>
						      <mat-panel-title>
						        Parental Guidance
						      </mat-panel-title>
						      <mat-panel-description> 
						        <i class="far fa-plus-circle" *ngIf="!exp6.expanded"></i>
						        <i class="far fa-minus-circle" *ngIf="exp6.expanded"></i>
						      </mat-panel-description>
						    </mat-expansion-panel-header>
						    <p>
						    	This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
							</p>
							<p>
								First we will meet online and chat via Zoom. You will come with me while I feed the mischievous sheep in their cozy barn. You will see them up close, hear birdsong, and see the hens pottering about. I will tell you some sheep stories, and you can ask me questions. Please bring your own sheep fun fact to share, and use the chat sidebar to engage with fellow guests.
						    </p>
						  </mat-expansion-panel>
						</mat-accordion>
					</div>
					<!-- banner bottom -->
					<div class="badge-n-banner-btm hidden-xs">
						<div class="badge-box">
							<img src="assets/images/badge-2.png" alt="">
						</div>
					</div>
				</div>
				<!-- Sidebar -->
				<div class="content-right">
					<div class="sidebar-item">
		                <div class="sticky hidden-xs">

		                	<!-- instructor panel for desktop-->
		                	<div class="sidebar-panel-box">
		                		<mat-expansion-panel class="sidebar-panel">
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        <div class="img-holder">
			                				<img src="assets/images/placeholder/instructor.png" alt="">
			                			</div>
			                			<div class="title-holder">
			                				<h1>Kimberly Simpson</h1>
			                				<p>About the instructor</p>
			                			</div>
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <div class="panel-body">
			                			<p>
			                				This is a virtual, online experience with Hamish, Dougal, and Lochie the sheep. Although they’re naughty sheep, this peaceful experience in our farmyard will help you to relax with breathing exercises and meditation. 
		                				</p>
	                					<p>
											First we will meet online and chat farmyard will help you to relax with breathing.
			                			</p>
			                			<p class="view-profile">
			                				<a href="javascript:;">
			                					View instructor profile
			                				</a>
			                			</p>
			                		</div>
								  </mat-expansion-panel>
		                	</div>

		                	<!-- Available dates & times for desktop -->
		                	<div class="sidebar-panel-box">
		                		<mat-expansion-panel class="sidebar-panel" [expanded]="true">
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        <div class="icon-holder">
			                				<i class="fal fa-calendar"></i>
			                			</div>
			                			<div class="title-holder">
			                				<h1>Available dates & times</h1>
			                				<p>for Shredder Ski in City, State</p>
			                			</div>
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <!-- One Time -->
								    <div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/one-time'">
								    	<div class="date-times-box">
								    		<div class="box-header">
								    			<ul class="days">
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															M
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															W
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															F
														</a>
													</li>
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
												</ul>
								    		</div>
								    		<div class="box-body">
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">Monday, Nov. 1</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">2 pm – 2:45 pm • One time</div>
								    					<!-- <div class="item-left">2 spots left</div> -->
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">Monday, Nov. 1</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">2 pm – 2:45 pm • One time</div>
								    					<div class="item-left">2 spots left</div>
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">Monday, Nov. 1</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">2 pm – 2:45 pm • One time</div>
								    					<!-- <div class="item-left">2 spots left</div> -->
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>

								    			<div class="showmore-btn-holder">
								    				<a href="javascript:;">
									    				Show 3 More sessions
									    			</a>
								    			</div>

								    		</div>
								    	</div>
			                		</div>
								    <!-- Multi Day -->
								    <div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/multi-day'">
								    	<div class="multi-day-btns">
								    		<button type="button" class="btn btn-multiday active" mat-button>
								    			Jan 6 – Apr 12
								    		</button>
								    		<button type="button" class="btn btn-multiday" mat-button>
								    			Apr 16 – May 6
								    		</button>
								    	</div>
								    	<div class="date-times-box">
								    		<div class="box-body multi-day-box-body">
								    			<div class="box-summary">
					                				<p class="p-sm">CLASS • BOULDER, CO</p>
					                				<h4 class="title">Sept. 14 – Nov 22, 2020</h4>
					                				<p class="subtitle">Winter Session Label</p>
					                			</div>
					                			<ul class="days">
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															M
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															W
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															F
														</a>
													</li>
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
												</ul>
												<div class="clearfix">
									    			<div class="enroll-item">
									    				<div class="flex-text">
									    					<div class="day">10 am - 10:45 am</div>
									    					<div class="timezone">MST</div>
									    				</div>
									    				<div class="flex-text">
									    					<div class="time">6 Classes • 3x a week</div>
									    					<!-- <div class="item-left">2 spots left</div> -->
									    				</div>
									    				<div class="enroll-btn-holder">
									    					<div class="amount">
									    						$45 USD
									    					</div>
									    					<div class="btn-holder">
									    						<button type="button" class="btn btn-block btn-enroll" mat-button>
									    							Enroll
									    						</button>
									    					</div>
									    				</div>
									    			</div>
									    		</div>
								    		</div>
								    	</div>
			                		</div>
			                		<!-- Once a Week -->
			                		<div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/once-a-week'">
			                			<div class="box-summary">
			                				<p class="p-sm">CLASS • BOULDER, CO</p>
			                				<h4 class="title">Sept. 14 – Nov 22, 2020</h4>
			                				<p class="subtitle">Winter Session Label</p>
			                			</div>
								    	<div class="date-times-box">
								    		<div class="box-header">
								    			<ul class="days">
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
													<li>
														<a href="javascript:;" class="active" matRipple>
															M
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" matRipple>
															W
														</a>
													</li>
													<li>
														<a href="javascript:;" matRipple>
															T
														</a>
													</li>
													<li>
														<a href="javascript:;" class="disabled" matRipple>
															F
														</a>
													</li>
													<li>
														<a href="javascript:;" matRipple>
															S
														</a>
													</li>
												</ul>
								    		</div>
								    		<div class="box-body">
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">10 am - 10:45 am</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">6 Classes • Once a week</div>
								    					<!-- <div class="item-left">2 spots left</div> -->
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">10 am - 11:15 am</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">6 Classes • Once a week</div>
								    					<div class="item-left">2 spots left</div>
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>
								    			<div class="enroll-item">
								    				<div class="flex-text">
								    					<div class="day">12 pm - 12:45 pm</div>
								    					<div class="timezone">MST</div>
								    				</div>
								    				<div class="flex-text">
								    					<div class="time">6 Classes • Once a week</div>
								    					<!-- <div class="item-left">2 spots left</div> -->
								    				</div>
								    				<div class="enroll-btn-holder">
								    					<div class="amount">
								    						$45 USD
								    					</div>
								    					<div class="btn-holder">
								    						<button type="button" class="btn btn-block btn-enroll" mat-button>
								    							Enroll
								    						</button>
								    					</div>
								    				</div>
								    			</div>

								    			<div class="showmore-btn-holder">
								    				<a href="javascript:;">
									    				Show 3 More sessions
									    			</a>
								    			</div>

								    		</div>
								    	</div>
			                		</div>
								  </mat-expansion-panel>
		                	</div>

		                </div>
		                
		                <!-- Available dates & times for mobile -->
		                <div class="sidebar-panel-box visible-xs">
	                		<div class="panel-header">
	                			<div class="title-holder">
	                				<h1>Dates & Times</h1>
	                			</div>
	                		</div>
						    <!-- One Time -->
						    <div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/one-time'">
						    	<div class="date-times-box">
						    		<div class="box-header">
						    			<ul class="days">
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													M
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													W
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													F
												</a>
											</li>
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
										</ul>
						    		</div>
						    		<div class="box-body">
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">Monday, Nov. 1</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">2 pm – 2:45 pm • One time</div>
						    					<!-- <div class="item-left">2 spots left</div> -->
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">Monday, Nov. 1</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">2 pm – 2:45 pm • One time</div>
						    					<div class="item-left">2 spots left</div>
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">Monday, Nov. 1</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">2 pm – 2:45 pm • One time</div>
						    					<!-- <div class="item-left">2 spots left</div> -->
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>

						    			<div class="showmore-btn-holder">
						    				<a href="javascript:;">
							    				Show 3 More sessions
							    			</a>
						    			</div>

						    		</div>
						    	</div>
	                		</div>
						    <!-- Multi Day -->
						    <div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/multi-day'">
						    	<div class="multi-day-btns">
						    		<button type="button" class="btn btn-multiday active" mat-button>
						    			Jan 6 – Apr 12
						    		</button>
						    		<button type="button" class="btn btn-multiday" mat-button>
						    			Apr 16 – May 6
						    		</button>
						    	</div>
						    	<div class="date-times-box">
						    		<div class="box-body multi-day-box-body">
						    			<div class="box-summary">
			                				<p class="p-sm">CLASS • BOULDER, CO</p>
			                				<h4 class="title">Sept. 14 – Nov 22, 2020</h4>
			                				<p class="subtitle">Winter Session Label</p>
			                			</div>
			                			<ul class="days">
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													M
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													W
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													F
												</a>
											</li>
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
										</ul>
										<div class="clearfix">
							    			<div class="enroll-item">
							    				<div class="flex-text">
							    					<div class="day">10 am - 10:45 am</div>
							    					<div class="timezone">MST</div>
							    				</div>
							    				<div class="flex-text">
							    					<div class="time">6 Classes • 3x a week</div>
							    					<!-- <div class="item-left">2 spots left</div> -->
							    				</div>
							    				<div class="enroll-btn-holder">
							    					<div class="amount">
							    						$45 USD
							    					</div>
							    					<div class="btn-holder">
							    						<button type="button" class="btn btn-block btn-enroll" mat-button>
							    							Enroll
							    						</button>
							    					</div>
							    				</div>
							    			</div>
							    		</div>
						    		</div>
						    	</div>
	                		</div>
	                		<!-- Once a Week -->
	                		<div class="panel-body available-date-times-holder" *ngIf="router.url === '/class/once-a-week'">
	                			<div class="box-summary">
	                				<p class="p-sm">CLASS • BOULDER, CO</p>
	                				<h4 class="title">Sept. 14 – Nov 22, 2020</h4>
	                				<p class="subtitle">Winter Session Label</p>
	                			</div>
						    	<div class="date-times-box">
						    		<div class="box-header">
						    			<ul class="days">
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
											<li>
												<a href="javascript:;" class="active" matRipple>
													M
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" matRipple>
													W
												</a>
											</li>
											<li>
												<a href="javascript:;" matRipple>
													T
												</a>
											</li>
											<li>
												<a href="javascript:;" class="disabled" matRipple>
													F
												</a>
											</li>
											<li>
												<a href="javascript:;" matRipple>
													S
												</a>
											</li>
										</ul>
						    		</div>
						    		<div class="box-body">
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">10 am - 10:45 am</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">6 Classes • Once a week</div>
						    					<!-- <div class="item-left">2 spots left</div> -->
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">10 am - 11:15 am</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">6 Classes • Once a week</div>
						    					<div class="item-left">2 spots left</div>
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>
						    			<div class="enroll-item">
						    				<div class="flex-text">
						    					<div class="day">12 pm - 12:45 pm</div>
						    					<div class="timezone">MST</div>
						    				</div>
						    				<div class="flex-text">
						    					<div class="time">6 Classes • Once a week</div>
						    					<!-- <div class="item-left">2 spots left</div> -->
						    				</div>
						    				<div class="enroll-btn-holder">
						    					<div class="amount">
						    						$45 USD
						    					</div>
						    					<div class="btn-holder">
						    						<button type="button" class="btn btn-block btn-enroll" mat-button>
						    							Enroll
						    						</button>
						    					</div>
						    				</div>
						    			</div>

						    			<div class="showmore-btn-holder">
						    				<a href="javascript:;">
							    				Show 3 More sessions
							    			</a>
						    			</div>

						    		</div>
						    	</div>
	                		</div>
	                	</div>
		            </div>					
				</div>
			</div>
		</div>
	</div>
</div>