import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';
export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  constructor() { }

  // Input dropdown
  opened: boolean;

  // placeholder values
  businessName = "Mike’s ABCs";
  businessAddress = "7000 Broadw";

  // progressbar
  progressbarValue = 20;

  showStep = 1;
  stepToggle(index){
    this.showStep =index;
  }
  progressValue(index){
  	this.progressbarValue = index;
  }
  // more btn text
  isButtonVisible = null;
  ButtonVisible(index){
    this.isButtonVisible = index;
  }

  // ====================Target demographics Chips====================
  openedTargetdemographics: boolean;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [
    {name: 'Basketball'},
    {name: 'Dance'}
  ];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }
  // ====================//End Target demographics Chips====================

  // Video palyer==================
    showVideo:boolean = true;
    // get the component instance to have access to plyr instance
    @ViewChild(PlyrComponent)
    plyr: PlyrComponent;

    // or get it from plyrInit event
    player: Plyr; 

    videoSources: Plyr.Source[] = [
      {
      src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
        type: 'video/mp4'
      },
    ];
    options: Plyr.Options = {
      autoplay: false,
      controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    }
  // End Video==============

  ngOnInit(): void {
    // Add class to make full screen(without header and footer)=============
  	const body = document.getElementsByTagName('body')[0];
    body.classList.add('fullscreen-body');
    // include main js file
    this.loadScript('../../assets/js/main.js'); 
  }
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('fullscreen-body');
  }
  public loadScript(url) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
