<section class="listings-template">
	<div class="container">
		<!-- listing tabs/navs -->
		<div class="tab-navs-holder">
			<ul class="tab-navs">
				<li>
					<a href="javascript:;" matRipple class="active">
						Listings
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Transactions
					</a>
				</li>
				<li>
					<a href="javascript:;" matRipple>
						Manage Staff & Locations
					</a>
				</li>
				<li class="select-location">
					<a href="javascript:;" class="dropdown-ctrl" [ngClass]="{'menu-open': menuTrigger.menuOpen}" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()">
						<i class="fas fa-map-marker-alt"></i>
						1729 Majectic Dr STE 1, Lafayette, CO
						<i class="fas fa-chevron-down dropdown-icon"></i>
					</a>
			        <mat-menu class="dropdown-location-menu" #menu="matMenu" [overlapTrigger]="false" xPosition="before">
					    <span (mouseleave)="menuTrigger.closeMenu()">
					     <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Spring Full Drive, STE 1, Lafayett...
				          </a>
				          <a href="javascript:;" matRipple mat-menu-item>
				          	<i class="fas fa-map-marker-alt"></i>
				          	1729 Majectic Dr STE 1, Lafayette, CO
				          </a>
					    </span>
					</mat-menu>
				</li>
			</ul>
		</div>
		<!-- top btn-holder -->
		<div class="row top-btn-holder">
			<div class="col-auto mr-auto">
				<div class="dropdown btn-status-holder">
				  <button class="btn btn-status" mat-button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Active
				    <i class="fas fa-chevron-down dropdown-icon"></i>
				  </button>
				  <div class="dropdown-menu">
				    <a href="javascript:;" matRipple>
					  	Enrolled
				  	</a>
				  	<a href="javascript:;" matRipple>
					  	Disable
				  	</a>
				  	<a href="javascript:;" matRipple>
					  	Disenrolled
				  	</a>
				  </div>
				</div>
			</div>
			<div class="col-auto">
				<button class="btn btn-create-listing" mat-button type="button">Create Listing</button>
			</div>
		</div>
		<!-- Listings expansion panel -->
		<div class="expansion-panel-holder">
			<mat-accordion class="expansion-panel-listing">
			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="title-holder">
			      		<div class="img-holder">
			      			<img src="assets/images/placeholder/listing-tbl-img.jpg" alt="" />
			      		</div>
			      		<div class="title">
			      			Let's Travel the World! - South America
			      		</div>
			      	</div>
			      	<div class="flex-item">
			      		3 - 4 years<br />
			      		<small>Age range</small>
			      	</div>
			      	<div class="flex-item duration">
			      		One’s a week
			      	</div>
			      	<div class="flex-item">
			      		Online
			      	</div>
			      	<div class="flex-item">
			      		Published
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			    <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	<div class="row input-n-btn-holder">
			    		<div class="col-auto mr-auto">
			    			<div class="calendar-input-box">
			    				<a href="javascript:;" class="calendar-icon" (click)="campaignOnePicker.open()">
			    					<i class="far fa-calendar-alt"></i>
			    				</a>
			    				<mat-date-range-input
								    [formGroup]="campaignOne"
								    [rangePicker]="campaignOnePicker">
								    <input matStartDate placeholder="Start date" formControlName="start">
								    <input matEndDate placeholder="End date" formControlName="end">
							  	</mat-date-range-input>
							  	<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
							  	<a href="javascript:;" class="prev" (click)="campaignOnePicker.open()">
			    					<i class="far fa-chevron-left"></i>
			    				</a>
			    				<a href="javascript:;" class="next" (click)="campaignOnePicker.open()">
			    					<i class="far fa-chevron-right"></i>
			    				</a>
			    				<!-- <div class="input-box">
			    					<input type="text" class="form-control" value="Mon, Dec 28">
			    					<a href="javascript:;" class="prev">
				    					<i class="far fa-chevron-left"></i>
				    				</a>
				    				<a href="javascript:;" class="next">
				    					<i class="far fa-chevron-right"></i>
				    				</a>
			    				</div>
			    				<div class="input-box">
			    					<input type="text" class="form-control" value="Mon, Dec 28">
			    					<a href="javascript:;" class="prev">
				    					<i class="far fa-chevron-left"></i>
				    				</a>
				    				<a href="javascript:;" class="next">
				    					<i class="far fa-chevron-right"></i>
				    				</a>
			    				</div> -->
			    			</div>
			    		</div>
			    		<div class="col-auto">
			    			<ul class="list-inline">
			    				<li>
			    					<a href="javascript:;">Edit Listing</a>
			    				</li>
			    				<li>
			    					<a href="javascript:;">Create Session</a>
			    				</li>
			    			</ul>
			    		</div>
			    	</div>
			    	<div class="row analytics-box-holder">
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>All Visits</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Conversion</h4>
			    				<h2>
			    					$12,394 
			    					<small class="text-danger">
			    						2.36%
			    						<i class="fas fa-long-arrow-down"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Revenue</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Transactions</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>
			    					ATV 
			    					<i class="fas fa-question-circle"></i>
			    				</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    	</div>

			    	<div class="table-holder">
			    		<div class="table-responsive">
						  <table class="table table-borderless table-hover">
						    <tbody>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets once</small>
						    		</td>
						    		<td width="14%">
						    			1 - 1:45 pm
						    			<br />
						    			<small>45m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			7
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			10
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Private
						    			<i class="fas fa-eye-slash fa-flip-horizontal"></i>
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets over 3 days</small>
						    		</td>
						    		<td width="14%">
						    			12 - 2 pm
						    			<br />
						    			<small>2h</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			10
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets every Friday</small>
						    		</td>
						    		<td width="14%">
						    			4 - 7 pm
						    			<br />
						    			<small>3h 2m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			12
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    </tbody>
						  </table>
						</div>
			    	</div>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="title-holder">
			      		<div class="img-holder">
			      			<img src="assets/images/placeholder/listing-tbl-img.jpg" alt="" />
			      		</div>
			      		<div class="title">
			      			The Days of the Week - Say It, Sing It, Write It and explore
			      		</div>
			      	</div>
			      	<div class="flex-item">
			      		4 - 5 years<br />
			      		<small>Age range</small>
			      	</div>
			      	<div class="flex-item duration">
			      		Two times a week
			      	</div>
			      	<div class="flex-item">
			      		On-demand
			      	</div>
			      	<div class="flex-item text-danger">
			      		Draft
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			    <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	<div class="row input-n-btn-holder">
			    		<div class="col-auto mr-auto">
			    			<div class="calendar-input-box">
			    				<a href="javascript:;" class="calendar-icon" (click)="campaignOnePicker2.open()">
			    					<i class="far fa-calendar-alt"></i>
			    				</a>
			    				<mat-date-range-input
								    [formGroup]="campaignOne"
								    [rangePicker]="campaignOnePicker2">
								    <input matStartDate placeholder="Start date" formControlName="start">
								    <input matEndDate placeholder="End date" formControlName="end">
							  	</mat-date-range-input>
							  	<mat-date-range-picker #campaignOnePicker2></mat-date-range-picker>
							  	<a href="javascript:;" class="prev" (click)="campaignOnePicker2.open()">
			    					<i class="far fa-chevron-left"></i>
			    				</a>
			    				<a href="javascript:;" class="next" (click)="campaignOnePicker2.open()">
			    					<i class="far fa-chevron-right"></i>
			    				</a>
			    			</div>
			    		</div>
			    		<div class="col-auto">
			    			<ul class="list-inline">
			    				<li>
			    					<a href="javascript:;">Edit Listing</a>
			    				</li>
			    				<li>
			    					<a href="javascript:;">Create Session</a>
			    				</li>
			    			</ul>
			    		</div>
			    	</div>
			    	<div class="row analytics-box-holder">
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>All Visits</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Conversion</h4>
			    				<h2>
			    					$12,394 
			    					<small class="text-danger">
			    						2.36%
			    						<i class="fas fa-long-arrow-down"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Revenue</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Transactions</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>
			    					ATV 
			    					<i class="fas fa-question-circle"></i>
			    				</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    	</div>

			    	<div class="table-holder">
			    		<div class="table-responsive">
						  <table class="table table-borderless table-hover">
						    <tbody>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets once</small>
						    		</td>
						    		<td width="14%">
						    			1 - 1:45 pm
						    			<br />
						    			<small>45m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			7
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			10
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Private
						    			<i class="fas fa-eye-slash fa-flip-horizontal"></i>
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets over 3 days</small>
						    		</td>
						    		<td width="14%">
						    			12 - 2 pm
						    			<br />
						    			<small>2h</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			10
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets every Friday</small>
						    		</td>
						    		<td width="14%">
						    			4 - 7 pm
						    			<br />
						    			<small>3h 2m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			12
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    </tbody>
						  </table>
						</div>
			    	</div>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="title-holder">
			      		<div class="img-holder">
			      			<img src="assets/images/placeholder/listing-tbl-img.jpg" alt="" />
			      		</div>
			      		<div class="title">
			      			The Days of the Week - Say It, Sing It, Write It
			      		</div>
			      	</div>
			      	<div class="flex-item">
			      		2 - 7 years<br />
			      		<small>Age range</small>
			      	</div>
			      	<div class="flex-item duration">
			      		One’s a week
			      	</div>
			      	<div class="flex-item">
			      		Local
			      	</div>
			      	<div class="flex-item">
			      		Private <i class="fas fa-eye-slash fa-flip-horizontal"></i>
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			    <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	<div class="row input-n-btn-holder">
			    		<div class="col-auto mr-auto">
			    			<div class="calendar-input-box">
			    				<a href="javascript:;" class="calendar-icon" (click)="campaignOnePicker3.open()">
			    					<i class="far fa-calendar-alt"></i>
			    				</a>
			    				<mat-date-range-input
								    [formGroup]="campaignOne"
								    [rangePicker]="campaignOnePicker3">
								    <input matStartDate placeholder="Start date" formControlName="start">
								    <input matEndDate placeholder="End date" formControlName="end">
							  	</mat-date-range-input>
							  	<mat-date-range-picker #campaignOnePicker3></mat-date-range-picker>
							  	<a href="javascript:;" class="prev" (click)="campaignOnePicker3.open()">
			    					<i class="far fa-chevron-left"></i>
			    				</a>
			    				<a href="javascript:;" class="next" (click)="campaignOnePicker3.open()">
			    					<i class="far fa-chevron-right"></i>
			    				</a>
			    			</div>
			    		</div>
			    		<div class="col-auto">
			    			<ul class="list-inline">
			    				<li>
			    					<a href="javascript:;">Edit Listing</a>
			    				</li>
			    				<li>
			    					<a href="javascript:;">Create Session</a>
			    				</li>
			    			</ul>
			    		</div>
			    	</div>
			    	<div class="row analytics-box-holder">
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>All Visits</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Conversion</h4>
			    				<h2>
			    					$12,394 
			    					<small class="text-danger">
			    						2.36%
			    						<i class="fas fa-long-arrow-down"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Revenue</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Transactions</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>
			    					ATV 
			    					<i class="fas fa-question-circle"></i>
			    				</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    	</div>

			    	<div class="table-holder">
			    		<div class="table-responsive">
						  <table class="table table-borderless table-hover">
						    <tbody>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets once</small>
						    		</td>
						    		<td width="14%">
						    			1 - 1:45 pm
						    			<br />
						    			<small>45m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			7
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			10
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Private
						    			<i class="fas fa-eye-slash fa-flip-horizontal"></i>
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets over 3 days</small>
						    		</td>
						    		<td width="14%">
						    			12 - 2 pm
						    			<br />
						    			<small>2h</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			10
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets every Friday</small>
						    		</td>
						    		<td width="14%">
						    			4 - 7 pm
						    			<br />
						    			<small>3h 2m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			12
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    </tbody>
						  </table>
						</div>
			    	</div>
			    </div>

			  </mat-expansion-panel>

			  <mat-expansion-panel>
			    <mat-expansion-panel-header>
			      <div class="panel-cst-header">
			      	<div class="title-holder">
			      		<div class="img-holder">
			      			<img src="assets/images/placeholder/listing-tbl-img.jpg" alt="" />
			      		</div>
			      		<div class="title">
			      			The Days of the Week - Say It, Sing It, Write It
			      		</div>
			      	</div>
			      	<div class="flex-item">
			      		10 - 14 years<br />
			      		<small>Age range</small>
			      	</div>
			      	<div class="flex-item duration">
			      		One’s a week
			      	</div>
			      	<div class="flex-item">
			      		Online
			      	</div>
			      	<div class="flex-item">
			      		Published
			      	</div>
			      </div>
			    </mat-expansion-panel-header>
			    <!-- Expansion Panel Body/Content -->
			    <div class="panel-cst-body">
			    	<div class="row input-n-btn-holder">
			    		<div class="col-auto mr-auto">
			    			<div class="calendar-input-box">
			    				<a href="javascript:;" class="calendar-icon" (click)="campaignOnePicker4.open()">
			    					<i class="far fa-calendar-alt"></i>
			    				</a>
			    				<mat-date-range-input
								    [formGroup]="campaignOne"
								    [rangePicker]="campaignOnePicker4">
								    <input matStartDate placeholder="Start date" formControlName="start">
								    <input matEndDate placeholder="End date" formControlName="end">
							  	</mat-date-range-input>
							  	<mat-date-range-picker #campaignOnePicker4></mat-date-range-picker>
							  	<a href="javascript:;" class="prev" (click)="campaignOnePicker4.open()">
			    					<i class="far fa-chevron-left"></i>
			    				</a>
			    				<a href="javascript:;" class="next" (click)="campaignOnePicker4.open()">
			    					<i class="far fa-chevron-right"></i>
			    				</a>
			    			</div>
			    		</div>
			    		<div class="col-auto">
			    			<ul class="list-inline">
			    				<li>
			    					<a href="javascript:;">Edit Listing</a>
			    				</li>
			    				<li>
			    					<a href="javascript:;">Create Session</a>
			    				</li>
			    			</ul>
			    		</div>
			    	</div>
			    	<div class="row analytics-box-holder">
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>All Visits</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Conversion</h4>
			    				<h2>
			    					$12,394 
			    					<small class="text-danger">
			    						2.36%
			    						<i class="fas fa-long-arrow-down"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Revenue</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>Transactions</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    		<div class="col flex-item">
			    			<div class="analytic-box">
			    				<h4>
			    					ATV 
			    					<i class="fas fa-question-circle"></i>
			    				</h4>
			    				<h2>
			    					$12,394 
			    					<small>
			    						1.07%
			    						<i class="fas fa-long-arrow-up"></i>
			    					</small>
			    				</h2>
			    			</div>
			    		</div>
			    	</div>

			    	<div class="table-holder">
			    		<div class="table-responsive">
						  <table class="table table-borderless table-hover">
						    <tbody>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets once</small>
						    		</td>
						    		<td width="14%">
						    			1 - 1:45 pm
						    			<br />
						    			<small>45m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			7
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			10
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Private
						    			<i class="fas fa-eye-slash fa-flip-horizontal"></i>
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets over 3 days</small>
						    		</td>
						    		<td width="14%">
						    			12 - 2 pm
						    			<br />
						    			<small>2h</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			10
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    	<tr>
						    		<td width="23%">
						    			Tues, May 14 - Jun 1
						    			<br />
						    			<small>Meets every Friday</small>
						    		</td>
						    		<td width="14%">
						    			4 - 7 pm
						    			<br />
						    			<small>3h 2m</small>
						    		</td>
						    		<td width="20%">
						    			<div class="flex-box">
						    				<div class="flex-item bdr-rt txt-right">
								    			12
								    			<br />
								    			<small>Enrolled</small>
								    		</div>
							    			<div class="flex-item">
								    			0
								    			<br />
								    			<small>Open</small>
								    		</div>
								    		<div class="caret-box">
								    			<a href="javascript:;" class="caret-up">
								    				<i class="far fa-triangle"></i>
								    				<i class="fas fa-triangle"></i>
								    			</a>
								    			<a href="javascript:;" class="caret-down">
								    				<i class="far fa-triangle fa-rotate-180"></i>
								    				<i class="fas fa-triangle fa-rotate-180"></i>
								    			</a>
								    		</div>
							    		</div>
						    		</td>
						    		<td width="15%">
						    			Helen R.
						    			<br />
						    			<small>Instructor</small>
						    		</td>
						    		<td>
						    			Published
						    		</td>
						    	</tr>
						    </tbody>
						  </table>
						</div>
			    	</div>
			    </div>

			  </mat-expansion-panel>

			</mat-accordion>
		</div>
	</div>
</section>