import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-search-by-filters',
  templateUrl: './search-by-filters.component.html',
  styleUrls: ['./search-by-filters.component.scss']
})
export class SearchByFiltersComponent implements OnInit {
  constructor() { }

  // Filter by Location=========  
  searchLocationFilter = '80024';
  public showLocation:boolean = false;
  toggleLocation() {
    this.showLocation = !this.showLocation;
  }

  // Filter by Age=========
  public showAge:boolean = false;
  toggleAge() {
    this.showAge = !this.showAge;
  }
  rangeSliderAgeForm: FormGroup = new FormGroup({
    rangeSliderAgeControl: new FormControl([3, 12])
  });
  optionsRangeSliderAge: Options = {
    floor: 0,
    ceil: 30
  };
  resetRangeSliderAgeForm(): void {
    this.rangeSliderAgeForm.reset({rangeSliderAgeControl: [0, 30]});
  }

  // Filter by Day and Time=========
  public showDateTime:boolean = false;
  toggleDateTime() {
    this.showDateTime = !this.showDateTime;
  }

  // Filter by Today or Date=========
  public showTodayOrDate:boolean = false;
  toggleTodayOrDate() {
    this.showTodayOrDate = !this.showTodayOrDate;
  } 
  rangeSliderTodayOrDateForm: FormGroup = new FormGroup({
    rangeSliderTodayOrDateControl: new FormControl([2, 7])
  });  
  optionsTodayOrDate: Options = {
    floor: 1,
    ceil: 11,
    translate: (value: number): string => {
      return value + ' PM';
    },
    combineLabels: (minValue: string, maxValue: string): string => {
      return minValue + ' to ' + maxValue;
    }
  };
  resetRangeSliderTodayOrDateForm(): void {
    this.rangeSliderTodayOrDateForm.reset({rangeSliderTodayOrDateControl: [1, 11]});
  }

  // jquery===============
  ngOnInit(): void {
  	const body = document.getElementsByTagName('body')[0];
    body.classList.add('nav-search-visible');
    this.loadScript('../../assets/js/main.js');  
  }
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('nav-search-visible');
  }
  public loadScript(url) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
