import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Add class to make full screen(without header and footer)=============
  	const body = document.getElementsByTagName('body')[0];
    body.classList.add('fullscreen-body');
  }
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('fullscreen-body');
  }

}
