import { Component, OnInit, ViewChild } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';

@Component({
  selector: 'app-recorded',
  templateUrl: './recorded.component.html',
  styleUrls: ['./recorded.component.scss']
})
export class RecordedComponent implements OnInit {

  	constructor() { }
  	
  	// get the component instance to have access to plyr instance
	@ViewChild(PlyrComponent)
	plyr: PlyrComponent;

	// or get it from plyrInit event
	player: Plyr;	

	videoSources: Plyr.Source[] = [
	  {
	 	src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
	  	type: 'video/mp4'
	  },
	];
	options: Plyr.Options = {
		autoplay: false,
		controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
	}
	poster = 'assets/images/placeholder/video-poster.jpg';

	played(event: Plyr.PlyrEvent) {
	  console.log('played', event);
	}

	play(): void {
	  this.player.play(); // or this.plyr.player.play()
	}

	ngOnInit(): void {
	    // Add class to specify this page=============
	  	const body = document.getElementsByTagName('body')[0];
	    body.classList.add('class-n-camp-layout');
	}
	ngOnDestroy(): void {
	    const body = document.getElementsByTagName('body')[0];
	    body.classList.remove('class-n-camp-layout');
	}

}
