<section class="auth-default-template">
	<div class="row-flex">
		<!-- Left side content -->
		<div class="flex-col lhs-box hidden-xs">
			<div class="logo-holder">
				<a routerLink="/home" class="logo">
					<img src="assets/images/wildster-white-logo.png" alt="">
				</a>
			</div>
		</div>
		<!-- auth Holder -->
		<div class="flex-col auth-holder">			
			<div class="auth-nav-top hidden-xs">
				Don’t have account? 
				<a routerLink="/sign-up">
					Register here
				</a>
			</div>
			<div class="flex-box">
				<div class="auth-box">
					<div class="logo-holder visible-xs">
						<a routerLink="/home">
							Wildster
						</a>
					</div>
					<h2 class="title">Sign In</h2>
					<p class="subtitle">Sign in with Social network or with your email.</p>
					<div class="social-login">
						<a href="javascript:;" mat-flat-button class="sign-with-google-btn">
							<i class="fab fa-google"></i>
							Sign in with Google
						</a>
						<a href="javascript:;" mat-flat-button class="facebook-btn">
							<i class="fab fa-facebook-f"></i>
						</a>
					</div>
					<div class="or-divider">
						<span>OR</span>
					</div>
					<div class="input-box-holder">
						<div class="input-box">
					      <label>Email</label>
					      <input type="email" class="form-control" placeholder="">
					      <!-- <input type="email" class="form-control error-input" placeholder=""> -->
					      <!-- <p class="error-msg">Sorry Email is not registered with us</p> -->
					    </div>
						<div class="input-box">
					      <label>Password</label>
					      <input type="password" class="form-control" placeholder="">
					      <!-- <input type="password" class="form-control error-input" placeholder=""> -->
					      <!-- <p class="error-msg">Sorry Email is not registered with us</p> -->
					      <a routerLink="/forget-password" class="forget-pass-mbl visible-xs">
							Forget Your Password?
						  </a>
					    </div>
						<div class="input-box hidden-xs">
					      	<a routerLink="/forget-password" class="forget-pass">
								Forget Your Password?
							</a>
					    </div>
						<div class="btn-holder">
					      	<button type="submit" mat-flat-button class="btn btn-block btn-submit">
						      	Sign In
						      	<i class="far fa-arrow-right"></i>
						    </button>
						    <p class="text-center visible-xs">
						    	Don’t have account? 
								<a routerLink="/sign-up">
									Register here
								</a>
						    </p>
					    </div>
					</div>
				</div>
				<div class="banner-holder visible-xs">
					<img src="assets/images/auth-bg-mobile.png" alt="">
				</div>
			</div>
		</div>
	</div>
</section>